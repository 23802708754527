import videojs from 'video.js'
import { MediaError } from '|>/shared/media-error'
import { updateDebugErrorHandlers } from './debug'
import type { ComplexExternalError, ExternalError } from './index.h'

/**
 * Global error map for any player instance
 */
const errorMap = new Map<number | string | symbol, ExternalError>()

/**
 * Extend global error map
 */
export function mapError(map: [number | string | symbol, ExternalError][]): void
export function mapError(map: Record<string, ExternalError>): void
export function mapError(
  code: number | string | symbol,
  error: ExternalError
): void
export function mapError(
  mapOrCode:
    | [number | string | symbol, ExternalError][]
    | Record<string, ExternalError>
    | number
    | string
    | symbol,
  error?: ExternalError
): void {
  if (typeof mapOrCode === 'object') {
    const entries = Array.isArray(mapOrCode)
      ? mapOrCode
      : Object.entries(mapOrCode)
    for (const [code, err] of entries) {
      errorMap.set(code, err)
    }
  } else if (error) {
    errorMap.set(mapOrCode, error)
  }

  // for debug
  // TODO: think about more convenient way to debug player errors
  updateDebugErrorHandlers(errorMap)
}

/**
 * Get mapped error from global error map
 * This function can get mapped error recursively,
 * e.g. if map looks like this:
 * ```
 * Map{ 'NE001' => 'NE002',
 *      'NE002' => 'NE003' }
 * ```
 * then mappedError('NE001') will return 'NE003'
 *
 * !!! Beware of infinite loops, don't create circular references in your map !!!
 */
function mappedError(
  code: number | string | symbol
): ComplexExternalError | undefined {
  let mapped = errorMap.get(code)
  if (mapped != null) {
    if (typeof mapped !== 'object' && mapped != code) {
      code = mapped
      const remapped = mappedError(code)
      if (remapped != null) {
        mapped = typeof remapped !== 'object' ? remapped : { code, ...remapped }
      }
    }
  } else return
  return typeof mapped !== 'object' ? { code: mapped } : mapped
}

/**
 * Hook to intercept error events and map catched errors using global error map
 */
videojs.hook(
  'beforeerror',
  (player, err: string | number | { code: number | string }) => {
    if (err == null) return err // reset error
    // convert to MediaError
    const error = new MediaError(err)

    // add source URL to error metadata
    const src = player.src()
    if (src) {
      error.metadata = { ...error.metadata, src }
    }

    // restore default message for native errors
    const defaultMessage = MediaError.defaultMessages[error.code]
    if (defaultMessage && error.message !== defaultMessage) {
      if (error.message) error.originalMessage ??= error.message
      error.message = defaultMessage
    }

    // get mapped error, if any, and map error code and message
    let mapped = mappedError(error.code)
    if (mapped) {
      const code =
        typeof mapped.code === 'symbol' ? mapped.code.description : mapped.code

      if (code) {
        error.originalCode = error.code
        error.code = code
      }

      if (mapped.title) {
        if (error.title) error.originalTitle ??= error.title
        error.title = mapped.title
      }

      if (mapped.message) {
        if (error.message) error.originalMessage ??= error.message
        error.message = mapped.message
      }
    }

    // return modified error
    return error
  }
)
