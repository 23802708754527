import videojs, { type Src } from 'video.js'
import type MediaErrorImpl from '../../videojs-src/src/js/media-error.js'

/*
 * Video.js does not export internal MediaError class,
 * while it has some advantages over the native W3C MediaError, like extra static members.
 *
 * But! I found that Tech has a simple and short method `error` which returns MediaError,
 * so we can use it to extract the MediaError class, using JavaScript voodoo reflection magic.
 */

type TMediaError = typeof MediaErrorImpl
export interface IMediaError extends TMediaError {
  new (value: number | string | { code: number | string }): IMediaError
  code: number | string
  title?: string
  message: string
  originalCode?: number | string
  originalTitle?: string
  originalMessage?: string
  metadata?: any

  // static and instance members
  MEDIA_ERR_CUSTOM: 0
  MEDIA_ERR_ABORTED: 1
  MEDIA_ERR_NETWORK: 2
  MEDIA_ERR_DECODE: 3
  MEDIA_ERR_SRC_NOT_SUPPORTED: 4
  MEDIA_ERR_ENCRYPTED: 5

  // this is not a real class definition, this is an interface for a class, defined in video.js source code.
  // if we need to add new errors, we need to think about it
  // PLAYER_ERR_TIMEOUT = -2,
  // TECH_ERROR = 10,
}

const Tech = videojs.getComponent('Tech') as unknown as Src.TTech

// create MediaError instance
// `error` method calls `this.trigger('error')`, so we need to fake this function
const error = Tech.prototype.error.call({ trigger() {} }, 0 as any)!

// extract MediaError class
export const MediaError = error.constructor as unknown as IMediaError
