import { Events } from '|>/shared/events'
import { register } from '|>/shared/vjs'
import { BaseButton } from '../base'

import './tv-button.css'

/**
 * Redirect to tv player page
 */
@register
export class TvButton extends BaseButton {
  override handleClick(event: Event) {
    event.preventDefault()
    event.stopPropagation()

    this.player_.trigger(Events.Player.RedirectTvClick)
  }
}

TvButton.options = {
  className: 'vjs-x-tv-button',
}
