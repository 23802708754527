import { type TPlayer } from 'video.js'
import { bind_ } from '|>/shared/fn'
import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'
import { LocalEvents } from './local-events'
import { SettingsButton } from './settings-button'
import { SettingsMenuContainer } from './settings-menu-container'

import './settings.css'

/**
 * Settings control, includes control bar button and settings menu,
 * which opens/closes when clicking on the button
 */
@register
export class Settings extends BaseComponent {
  declare gearButton: SettingsButton
  declare menuContainer: SettingsMenuContainer

  constructor(player: TPlayer, options: any) {
    super(player, options)

    // set links to this component for children
    this.gearButton.settings = this
    this.menuContainer.settings = this

    // if click outside the menu - close it
    this.handleClickOutside = bind_(this, this.handleClickOutside)
    document.addEventListener('mousedown', this.handleClickOutside, { capture: true }) // prettier-ignore
    document.addEventListener('touchstart', this.handleClickOutside, { capture: true }) // prettier-ignore
  }

  handleClickOutside(event: MouseEvent | TouchEvent) {
    if (!this.el_.contains(event.target as Node)) {
      this.menuContainer.trigger(LocalEvents.SettingsCloseMenu)
    }
  }

  override dispose() {
    document.removeEventListener('mousedown', this.handleClickOutside, { capture: true }) // prettier-ignore
    document.removeEventListener('touchstart', this.handleClickOutside, { capture: true }) // prettier-ignore
    super.dispose()
  }
}

Settings.options = {
  className: 'vjs-x-settings vjs-control',
  children: [
    // button to open settings menu
    SettingsButton.as('gearButton'),

    // settings menu with audio and subtitles options
    SettingsMenuContainer.as('menuContainer'),
  ],
}
