import { useEffect } from 'react'
import type { TPlayer } from 'video.js'
import type { EventType, EventTypeMap } from '|>/shared/events'
import { useEventCallback } from './useEventCallback'

/**
 * Hook to subscribe to VideoJS player events
 */
export function useEventListener<T extends keyof EventTypeMap>(
  player: TPlayer | null | undefined,
  event: T,
  callback: ((payload?: EventType<T>, event?: any) => void) | undefined
): void {
  // create stable callback wrapper for unstable callback function
  const handler = useEventCallback(callback)

  // subscribe (and unsubscribe) to the player event
  useEffect(() => {
    // invert arguments order
    // VideoJS events are triggered with event as first argument,
    // but we want to pass it as second argument to the callback,
    // because event's payload is more important than the event itself
    const fn = (event: any, arg?: any) => handler(arg, event)

    player?.on(event, fn)
    return () => {
      // do not unsubscribe from "dispose" event
      // because in that case unsubscription is happening before the event is triggered,
      // causing the event to be missed by `useEventListener` hook.
      // and unsubscribing from "dispose" event is not necessary anyways
      if ((event as any) === 'dispose') return
      player?.off(event, fn)
    }
  }, [player, event, handler])
}
