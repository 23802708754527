const LANGUAGES_MATCHES = [
  // Asian languages first - very distinct patterns
  {
    match: [/\b(hi|hin)\b/i, /\bhindi\b/i],
    title: 'हिंदी',
  },
  {
    match: [/\b(th|tha)\b/i, /\bthai\b/i],
    title: 'ไทย',
  },
  {
    match: [/\b(vi|vie)\b/i, /\bviet\b/i],
    title: 'Tiếng Việt',
  },
  {
    match: [/\b(zh|chi|cmn)\b/i, /\b(chinese|mandarin)\b/i],
    title: '中文',
  },
  {
    match: [/\b(ja|jpn)\b/i, /\bjapanese\b/i],
    title: '日本語',
  },
  {
    match: [/\b(ko|kor)\b/i, /\bkorean\b/i],
    title: '한국어',
  },

  // European languages with distinctive patterns
  {
    match: [/\b(fr|fra|fre)\b/i, /\bfrench\b/i, /\bfrançais\b/i],
    title: 'Français',
  },
  {
    match: [/\b(es|spa)\b/i, /\bspanish\b/i, /\bespañol\b/i],
    title: 'Español',
  },
  {
    match: [/\b(de|deu|ger)\b/i, /\bgerman\b/i, /\bdeutsch\b/i],
    title: 'Deutsch',
  },
  {
    match: [/\b(it|ita)\b/i, /\bitalian\b/i, /\bitaliano\b/i],
    title: 'Italiano',
  },
  {
    match: [/\b(pt|por)\b/i, /\bportuguese\b/i, /\bportuguês\b/i],
    title: 'Português',
  },
  {
    match: [/\b(ru|rus)\b/i, /\brussian\b/i, /\bрусский\b/i],
    title: 'Русский',
  },
  {
    match: [/\b(nl|nld|dut)\b/i, /\bdutch\b/i, /\bnederlands\b/i],
    title: 'Nederlands',
  },
  {
    match: [/\b(pl|pol)\b/i, /\bpolish\b/i, /\bpolski\b/i],
    title: 'Polski',
  },
  {
    match: [/\b(tr|tur)\b/i, /\bturkish\b/i, /\btürkçe\b/i],
    title: 'Türkçe',
  },

  // English last - most general but still bounded
  {
    match: [/\b(en|eng)\b/i, /\benglish\b/i],
    title: 'English',
  },
]

export function normalizeTracksTitles() {
  const past = new Map<string, number>()
  return (title?: string | null) => {
    if (title == null) return title
    for (const lang of LANGUAGES_MATCHES) {
      if (lang.match.some((m) => m.test(title!))) {
        title = lang.title
        const n = past.get(title)
        if (n == null) {
          past.set(title, 1)
        } else {
          past.set(title, n + 1)
          title = `${title} (${n + 1})`
        }
        return title
      }
    }
    return title
  }
}
