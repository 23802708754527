import videojs, { type TPlayer } from 'video.js'
import { register } from '|>/shared/vjs'
import { BaseControlBar } from '../base'

import './control-bar.css'

/**
 * Main control bar component
 * place for play/pause, volume, tile, seek to live, settings and so on controls
 * https://github.com/videojs/video.js/blob/e78bcc7b2d829fce01451cf105b918d8feec4548/src/js/control-bar/control-bar.js
 */
@register
export class ControlBar extends BaseControlBar {
  constructor(player: TPlayer, options: any) {
    super(player, options)

    // save reference to the control bar in the player instance
    // this is required for chromecast plugin `@silvermine/videojs-chromecast` to work,
    // because it searches for the control bar in the player instance
    ;(player as any).controlBar = this
  }
}

/**
 * adjust videojs format time function
 */
videojs.time.setFormatTime((seconds: number) => {
  if (isNaN(seconds) || seconds === Infinity) return '-:-:-'

  seconds = seconds < 0 ? 0 : seconds
  const s = Math.floor(seconds % 60)
  const m = Math.floor((seconds / 60) % 60)
  const h = Math.floor(seconds / 3600)

  return [h, m, s].map((v) => (v < 10 ? '0' + v : v)).join(':')
})
