export function asDepsArray(value: unknown, length: number): any[] {
  const empty = Array(length)

  if (value == null) {
    return empty
  }

  if (!Array.isArray(value)) {
    empty.splice(0, 1, value)
    return empty
  }

  return value.concat(empty).slice(0, length)
}
