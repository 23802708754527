import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'

import './play-pause-animation.css'

@register
export class PlayPauseAnimation extends BaseComponent {}

PlayPauseAnimation.options = {
  className: 'vjs-x-animation-play-pause',
}
