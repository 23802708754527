export const toAbs = (time?: number) => {
  if (time === undefined) return
  if (time < 24 * 60 * 60 * 1000) {
    // less than a day (or negative) -> relative time)
    return Date.now() + time
  }
  return time
}

/**
 * Get absolute time in format "12:34:56"
 */
export function absoluteTime({
  time,
  noTimezone = false,
}: {
  time: number
  noTimezone?: boolean
}) {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
    ...(noTimezone && { timeZone: 'UTC' }),
  } as const
  const format = new Intl.DateTimeFormat('en-US', options)
  return format.format(new Date(time))
}

export const EMPTY_TIME = '– –:– –:– –'
