import videojs from 'video.js'
import { Events } from '|>/shared/events'
import { UPDATE_REFRESH_INTERVAL, bind_, throttle } from '|>/shared/fn'
import { on, register } from '|>/shared/vjs'
import { BaseLoadProgressBar, BaseSlider } from '../base'
import { LivePlayProgressBar } from './live-play-progress-bar'

import './live-seek-bar.css'
import { MouseBar } from './mouse-bar'

const SEEK_COOLDOWN_MS = 500

const MANY_CLICKS_WAIT_MS = 400

const CHECK_NEW_URL_READY_INTERVAL = 300

const PROGRESS_BAR_LIVE_TOLERANCE_PIXELS = 7

/**
 * Live seekable bar
 */
@register
export class LiveSeekBar extends BaseSlider {
  declare playerEvent: string
  declare livePlayProgressBar: LivePlayProgressBar
  declare mouseBar: MouseBar

  @on(Events.Media.IsRewindPending)
  private isRewindPending: Events.Media.IsRewindPending = false

  @on(Events.Media.IsRewound)
  private isRewound: Events.Media.IsRewound = false

  @on(Events.Media.RewoundTargetSec)
  private rewoundTargetStartSec: Events.Media.RewoundTargetSec = 0

  @on(Events.Media.RewoundUrlSec)
  private rewoundUrlStartSec: Events.Media.RewoundUrlSec = 0

  updateInterval: number | undefined

  tempPercent: number | undefined // [0 - 1]
  start: number | undefined // stamp ms
  end: number | undefined // stamp ms

  constructor(player) {
    super(player)

    /* Handlers from this component is used by reference by rewind buttons and hotkeys */
    ;(player as any).seekBar = this

    this.updateOwnPositionForced = bind_(this, this.updateOwnPosition)
    this.updateOwnPosition = throttle(
      this.updateOwnPositionForced,
      UPDATE_REFRESH_INTERVAL
    )

    this.updateForced = bind_(this, this.update)
    this.update = throttle(this.updateForced, UPDATE_REFRESH_INTERVAL)

    this.handleKeyDown = bind_(this, this.handleKeyDown)
    videojs.on(document, 'keydown', this.handleKeyDown)
  }

  override createEl() {
    return super.createEl(
      'div',
      { className: 'vjs-progress-holder vjs-x-live-progress-holder' },
      { 'aria-label': this.localize('Live Progress Bar') }
    )
  }

  getLivePercent() {
    // atm we could go rewind only by user interaction -> new url
    // so any live playback network issues should not cause live edge be lost
    return 1
  }

  getRewoundPercent() {
    if (this.end === undefined || this.start === undefined) return

    const progressLengthMs = this.end - this.start
    const startMs = this.start
    const currentTime = this.player_.currentTime()

    let currentStamp: number
    if (this.rewoundTargetStartSec === this.rewoundUrlStartSec) {
      // that "timeshift" case
      currentStamp = this.rewoundTargetStartSec + currentTime
    } else {
      // that "dvr/rewind" case
      let realOffsetMs =
        (this.player_.liveDuration.estimatedEnd - this.player_.currentTime()) *
        1000
      if (realOffsetMs < 0) realOffsetMs = 0

      currentStamp = (this.end - realOffsetMs) / 1000
    }

    const currentStampMs = currentStamp * 1000

    return (currentStampMs - startMs) / progressLengthMs
  }

  getPercent() {
    const isTempPercent =
      this.tempPercent != null && this.tempPercent >= 0 && this.tempPercent <= 1

    if (isTempPercent || this.isRewindPending || this.totalShift)
      return this.tempPercent

    return this.getPlaybackPercent()
  }

  getPlaybackPercent() {
    if (this.isRewound) return this.getRewoundPercent()

    return this.getLivePercent()
  }

  override handleMouseMove(event, _mouseDown = false) {
    super.handleMouseMove(event)

    if (event) {
      event.stopPropagation()
    }

    if (!this.parentComponent_.isInteractive) return

    this.tempPercent = this.calculateDistance(event)
  }

  /*
   * Last progress bar click may trigger new url load and player init new sourceUrl
   * so we check isRewindPending, totalShift and keeping saved last click point in tempPercent
   */
  clearWithDelayTempPercent(trailing: boolean = false) {
    if (trailing)
      return window.setTimeout(
        bind_(this, this.clearWithDelayTempPercent),
        CHECK_NEW_URL_READY_INTERVAL
      )

    if (!this.isRewindPending && !this.totalShift)
      return (this.tempPercent = undefined)

    window.setTimeout(
      bind_(this, this.clearWithDelayTempPercent),
      CHECK_NEW_URL_READY_INTERVAL
    )
  }

  @on(Events.Player.GoLive)
  setTempPercentOnGoLive() {
    this.tempPercent = this.getPercent()
    // delay is to wait for isRewindPending become true
    this.clearWithDelayTempPercent(true)
  }

  override handleMouseDown(event) {
    if (event) {
      event.stopPropagation()
      // this prevents secondary click events on touch devices
      event.preventDefault()
    }

    super.handleMouseDown(event)
  }

  totalShift: number = 0
  private accumulateTimeout: NodeJS.Timeout | null = null
  private seekCooldownTimerId: ReturnType<typeof setTimeout> | null = null

  isLivePercent(percent?: number) {
    if (!percent) return false

    const elWidth = this.el_.offsetWidth
    const percentPerPixel = 1 / elWidth // one pixel presents such percent

    const liveTolerancePercent =
      percentPerPixel * PROGRESS_BAR_LIVE_TOLERANCE_PIXELS

    return (
      percent + liveTolerancePercent >= 1 &&
      elWidth >= PROGRESS_BAR_LIVE_TOLERANCE_PIXELS * 2
    )
  }

  isMinPercent(percent?: number) {
    if (!percent) return false

    return percent < 0
  }

  getNewPosition(params):
    | {
        percent: number
        stamp: number
      }
    | undefined {
    /* shiftSec - negative for left and positive for the right */
    if (!this.start || !this.end) return

    const { event, shiftSec } = params

    const progressLengthMs = this.end - this.start

    let percent
    let stamp

    if (event) {
      // this is the new playback percent, not normalized yet, that would be shown while loading
      percent = this.calculateDistance(event)

      if (!percent) return

      stamp = Math.round((this.start + progressLengthMs * percent) / 1000)
    }

    if (shiftSec) {
      const currentPercent = this.getPlaybackPercent()
      if (currentPercent == null) return

      percent =
        currentPercent +
        ((this.totalShift ?? shiftSec) * 1000) / progressLengthMs

      // stamp not needed for shift seek
    }

    return { percent, stamp }
  }

  trySeek(params: { event?: Event; shiftSec?: number; isAnimate?: boolean }) {
    if (this.seekCooldownTimerId) return

    if (!this.parentComponent_.isInteractive) return

    if (!this.end || !this.start) return

    const { shiftSec, isAnimate } = params

    const isShiftingBackwards = shiftSec && shiftSec < 0

    const { percent, stamp } = this.getNewPosition(params) ?? {}

    if (isAnimate) this.player_.tapHandler.animateSeek(shiftSec)

    this.tempPercent = percent
    this.clearWithDelayTempPercent(true) // delay clearing tempPercent, to show last user input

    /* Right border collision handling */
    if (this.isLivePercent(percent) && !isShiftingBackwards)
      return this.goLive()

    /* Left border collision handling */
    if (this.isMinPercent(percent) && isShiftingBackwards)
      return this.debouncedSeek(this.start / 1000)

    if (stamp) return this.debouncedSeek(stamp)

    if (shiftSec) {
      this.update() // updates playback position if seeking while in pause
      return this.throttledSeek(shiftSec)
    }
  }

  private debouncedSeek(rewindParam?: number) {
    if (this.seekCooldownTimerId) return

    this.doSeek(rewindParam)

    this.seekCooldownTimerId = setTimeout(() => {
      this.totalShift = 0
      this.seekCooldownTimerId = null
    }, SEEK_COOLDOWN_MS)
  }

  private throttledSeek(shift: number) {
    this.totalShift += shift

    // Reset seek call timeout if user clicks again within MANY_CLICKS_WAIT_MS
    if (this.accumulateTimeout) clearTimeout(this.accumulateTimeout)

    this.accumulateTimeout = setTimeout(() => {
      this.debouncedSeek(this.totalShift)
    }, MANY_CLICKS_WAIT_MS)
  }

  doSeek(value) {
    this.player_.trigger(Events.Player.LiveRewind, value)
  }

  goLive() {
    if (this.isRewound) {
      this.player_.trigger(Events.Player.GoLive)

      this.totalShift = 0

      this.seekCooldownTimerId = setTimeout(() => {
        this.seekCooldownTimerId = null
      }, SEEK_COOLDOWN_MS)
    }
  }

  override handleMouseUp(event) {
    if (event) {
      event.stopPropagation()
      // this prevents secondary click events on touch devices
      event.preventDefault()
    }

    super.handleMouseUp(event)

    this.trySeek({ event })
  }

  override handleKeyDown(_event) {
    // console.log('👉', event.key)
  }

  // stepForward() {
  //   console.log('stepForward!')
  //   // this.userSeek_(this.player_.currentTime() + STEP_SECONDS);
  // }

  // stepBack() {
  //   console.log('stepBack!')
  //   // this.userSeek_(this.player_.currentTime() - STEP_SECONDS);
  // }

  updateForced: typeof this.update
  override update() {
    return super.update()
    // this.updateOwnPosition(width, boundaries)
    // const percent = this.getPercent()
    // this.bar.update(percent)
  }

  /**
   * (------[======]--------)
   * a      x      y        b
   */
  updateOwnPositionForced: typeof this.updateOwnPosition
  updateOwnPosition(
    width: number,
    [a, x, y, b]: [number, number, number, number]
  ) {
    const w = width / (b - a)
    const left = x === a ? 0 : Math.max(w * (x - a), 0)
    const right = y === b ? 0 : Math.max(w * (b - y), 0)

    const el = this.el() as HTMLElement
    el.style['margin-left'] = `${left}px`
    el.style['margin-right'] = `${right}px`

    // update start and end points
    if (x !== 0 && y !== 1) {
      this.start = x
      this.end = y
    }
  }

  @on(['playing'])
  enableInterval() {
    if (this.updateInterval) {
      return
    }

    this.updateInterval = this.setInterval(this.update, UPDATE_REFRESH_INTERVAL)
  }

  @on(['ended', 'pause', 'waiting', 'dispose'])
  disableInterval() {
    if (!this.updateInterval) {
      return
    }

    this.clearInterval(this.updateInterval)
    this.updateInterval = undefined
  }

  override dispose() {
    videojs.off(document, 'keydown', this.handleKeyDown)
    super.dispose()
  }
}
LiveSeekBar.prototype.playerEvent = 'mousemove'

LiveSeekBar.options = {
  children: [
    LivePlayProgressBar.as('livePlayProgressBar'),
    MouseBar.as('mouseBar'),
    BaseLoadProgressBar.as('loadProgressBar'),
  ],
  barName: 'livePlayProgressBar',
}
