import type { TPlayer } from 'video.js'

/**
 * Get human readable playback status of the player
 */
export function getPlaybackStatus(player: TPlayer): string {
  if (player.error()) {
    return `error ${player.error()?.code}`
  }

  // HAVE_NOTHING = 0
  // HAVE_METADATA = 1
  // HAVE_CURRENT_DATA = 2
  // HAVE_FUTURE_DATA = 3
  // HAVE_ENOUGH_DATA = 4
  const isWaiting = player.readyState() < 3 // less than HAVE_FUTURE_DATA

  if (isWaiting && !player.paused()) {
    return 'waiting'
  }

  if (!player.hasStarted(undefined as any)) {
    return 'not_started'
  }

  if (player.ended()) {
    return 'ended'
  }

  if (player.paused()) {
    return player.currentTime() === 0 ? 'stopped' : 'paused'
  }

  return 'playing'
}

/**
 * Get human readable buffered time ranges of the player
 */
export function getBufferedTimeRanges(player: TPlayer): string {
  const buffered = player.buffered()
  const ranges: string[] = []
  for (let i = 0; i < buffered.length; i++) {
    let start = buffered.start(i)
    let end = buffered.end(i)
    if (typeof start === 'number' && typeof end === 'number' && start !== end) {
      start = start.toFixed(2)
      end = end.toFixed(2)
      ranges.push(`[${start} - ${end}]`)
    }
  }
  return ranges.length > 0 ? ranges.join(', ') : '-'
}

/**
 * Get human readable network state of the player
 */
export function getNetworkState(player: TPlayer): string {
  // - NETWORK_EMPTY (numeric value 0)
  //   The element has not yet been initialised. All attributes are in
  //   their initial states.
  // - NETWORK_IDLE (numeric value 1)
  //   The element's resource selection algorithm is active and has
  //   selected a resource, but it is not actually using the network at
  //   this time.
  // - NETWORK_LOADING (numeric value 2)
  //   The user agent is actively trying to download data.
  // - NETWORK_NO_SOURCE (numeric value 3)
  //   The element's resource selection algorithm is active, but it has
  //   not yet found a resource to use.

  const network = player.networkState()
  switch (network) {
    case 1:
      return 'idle'
    case 2:
      return 'loading'
    case 3:
      return 'no_source'
    case 0:
    default:
      return '-'
  }
}

/**
 * Get human readable ready state of the player
 */
export function getReadyState(player: TPlayer): string {
  // - HAVE_NOTHING (numeric value 0)
  //   No information regarding the media resource is available.
  // - HAVE_METADATA (numeric value 1)
  //   Enough of the resource has been obtained that the duration of the
  //   resource is available.
  // - HAVE_CURRENT_DATA (numeric value 2)
  //   Data for the immediate current playback position is available.
  // - HAVE_FUTURE_DATA (numeric value 3)
  //   Data for the immediate current playback position is available, as
  //   well as enough data for the user agent to advance the current
  //   playback position in the direction of playback.
  // - HAVE_ENOUGH_DATA (numeric value 4)
  //   The user agent estimates that enough data is available for
  //   playback to proceed uninterrupted.

  const ready = player.readyState()
  switch (ready) {
    case 0:
      return 'have nothing'
    case 1:
      return 'have metadata'
    case 2:
      return 'have current data'
    case 3:
      return 'have future data'
    case 4:
      return 'have enough data'
    default:
      return '-'
  }
}

/**
 * Get measured throughput of the player
 */
export function getMeasuredThroughput(player: TPlayer): number | undefined {
  try {
    const vhs = player.tech_?.vhs
    if (!vhs) {
      return undefined
    }
    return vhs.systemBandwidth
  } catch (ignored) {}
}

/**
 * Get encoded bitrate of the media playlist
 */
export function getEncodedBitrate(player: TPlayer): number | undefined {
  try {
    const vhs = player.tech_?.vhs
    if (!vhs) {
      return undefined
    }
    const bandwidth = vhs.playlists.media_.attributes.BANDWIDTH
    return bandwidth
  } catch (ignored) {}
}

/**
 * Compare two quality levels lists
 */
export function compareQualityLevels(levels1, levels2): boolean {
  return (
    levels1 != null &&
    levels2 != null &&
    levels1.length === levels2.length &&
    levels1.every((level, i) => level === levels2[i])
  )
}

/**
 * Select quality level
 */
export function selectQualityLevel(levels, selected): void {
  if (levels != null) {
    for (const level of levels) {
      level.enabled = level === selected || selected == null
    }
  }
}
