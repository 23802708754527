import {
  BaseComponent,
  BaseFullscreenToggle,
  BasePictureInPictureToggle,
  BaseSpacer,
} from '../base'
import { defaultPlayer } from '../player'
import { Player } from './player'

import { ChromeCastingMessage } from '|>/components/chrome-casting-message'
import { CloseButton } from '|>/components/close-button'
import { ControlBar } from '|>/components/control-bar'
import { PlayButton } from '|>/components/play-button'
import { PlayNext } from '|>/components/play-next'
import { PlayPrevious } from '|>/components/play-previous'
import { Poster } from '|>/components/poster'
import { ProgramButton } from '|>/components/program-button'
import { ProgressControl } from '|>/components/progress-control'
import { RewindButton } from '|>/components/rewind-button'
import { SeekToLive } from '|>/components/seek-to-live'
import { Settings } from '|>/components/settings'
import { TapToUnmute } from '|>/components/tap-to-unmute'
import { Title } from '|>/components/title'
import { TvButton } from '|>/components/tv-button'
import { VolumePanel } from '|>/components/volume-panel'

import './alternative-player.css'

export const alternativePlayer = Player.as(
  `${defaultPlayer.className}.vjs-x-player-alt`,
  [
    // Top bunk
    BaseComponent.as('.vjs-x-top-bunk', [
      // currently playing video title
      Title,

      // space between title and close button
      BaseSpacer,

      // toggle Picture-in-Picture
      BasePictureInPictureToggle.as(),

      // chromecast button
      // https://github.com/silvermine/videojs-chromecast/blob/ee87f9bcad423b48560cbb5340b5b577d61af853/src/js/components/ChromecastButton.js
      'chromecastButton',

      // close button
      CloseButton,
    ]),

    // Middle bunk
    BaseComponent.as('.vjs-x-middle-bunk', [
      Poster,

      // for "touch" devices only
      PlayPrevious,
      PlayButton,
      PlayNext,

      // chromecast message
      ChromeCastingMessage,

      // "Tap to unmute button" for touch devices combined
      TapToUnmute,
    ]),

    // Bottom bunk
    BaseComponent.as('.vjs-x-bottom-bunk', [
      // progress control
      ProgressControl,

      // control bar with play/pause, volume, live indicator(button) and so on controls
      ControlBar.as('.vjs-x-control-bar', [
        // central play toggle button
        PlayButton,

        // rewind left button
        RewindButton.as('.vjs-x-rewind-button.vjs-x-rewind-left-button'),

        // rewind right button
        RewindButton.as('.vjs-x-rewind-button.vjs-x-rewind-right-button'),

        // status of live edge and button to seek to live edge
        SeekToLive,

        // MuteToggle and VolumeControl combined
        VolumePanel,

        // space to move latter controls to the right side
        BaseSpacer,

        // Play next content button
        PlayPrevious,

        // Play previous content button
        PlayNext,

        // Show program view btn.
        ProgramButton,

        // Redirect to tv channel btn.
        TvButton,

        // Settings control, with button and menu
        Settings,

        // toggle fullscreen video
        BaseFullscreenToggle.as(),
      ]),
    ]),
  ]
)
