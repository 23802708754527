import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import type { TOptions, TPlayer } from 'video.js'
import { createPlayer, type Configuration } from '|>/core'
import { Events } from '|>/shared/events'

export type VideojsBoxProps = {
  options: TOptions
  configuration: Configuration
}

export type VideojsBoxRef = {
  wrapper: () => HTMLDivElement | null
  player: () => TPlayer | null
  slot: () => Element | null | undefined
}

/**
 * Bare bone React wrapper around VideoJS player, no more!
 * It creates a video tag and initializes VideoJS player inside it,
 * and exposes videojs wrapper element and player instance to the parent component
 */
export const VideojsBox = memo(forwardRef(_VideojsBox))
function _VideojsBox(
  { options, configuration }: VideojsBoxProps,
  ref: React.ForwardedRef<VideojsBoxRef>
) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const playerRef = useRef<TPlayer | Promise<TPlayer> | null>(null)

  // expose video tag and player references to parent component
  // prettier-ignore
  useImperativeHandle(ref, () => ({
    wrapper: () => wrapperRef.current,
    player: () => playerRef.current instanceof Promise ? null : playerRef.current,
    slot: () => playerRef.current instanceof Promise ? null : playerRef.current?.slot?.el(),
  }), [])

  useEffect(() => {
    const player = playerRef.current

    // create videojs player
    if (!player) {
      // videojs player needs to be _inside_ the component el for react 18 strict mode
      // see https://videojs.com/guides/react/#react-functional-component-and-useeffect-example
      const videoElement = document.createElement('video-js')
      wrapperRef.current?.appendChild(videoElement)

      // create videojs player itself
      // `createPlayer` function can return a Promise if some async initialization is needed
      playerRef.current = createPlayer(videoElement, options, configuration)

      // if player is a promise - resolve it and update reference
      if (playerRef.current instanceof Promise) {
        playerRef.current.then((player) => {
          playerRef.current = player
        })
      }
    }

    // in player already exists - can update _some_ of videojs options and configuration
    else {
      const update = (player: TPlayer) => {
        player.language(options.language)

        // trigger event to update options and configuration
        player.trigger(Events.Player.UpdateOptions, options)
        player.trigger(Events.Player.UpdateConfiguration, configuration)
      }

      // if player is a promise - resolve it and update options
      if (player instanceof Promise) {
        player.then(update)
      } else {
        update(player)
      }
    }
  }, [options, configuration])

  // dispose videojs player on unmount
  useEffect(() => {
    return () => {
      const player = playerRef.current
      playerRef.current = null

      const dispose = (player: TPlayer | null | undefined) =>
        player && !player.isDisposed() && player.dispose()

      if (player instanceof Promise) {
        player.then(dispose)
      } else {
        dispose(player)
      }
    }
  }, [])

  // `data-vjs-player` attribute is needed so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  return <div ref={wrapperRef} data-vjs-player className='vjs-wrapper' />
}
