import { div } from '|>/shared/h'
import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'

import './seek-animation.css'

@register
export class SeekAnimation extends BaseComponent {
  override createEl() {
    return div(
      '.vjs-x-seek-animation',
      null,
      div('.vjs-x-seek-background', null)
    )
  }
}
