import { type TPlayer } from 'video.js'
import { Events } from '|>/shared/events'
import { on, register } from '|>/shared/vjs'
import { BaseComponent } from '../base'

@register
export class LiveDuration extends BaseComponent {
  lastUpdateTime: number | undefined
  estimatedEnd: number = 0
  seekableEnd: number | undefined

  @on(Events.Media.IsRewound)
  private isRewound: Events.Media.IsRewound = false

  @on('timeupdate')
  onTimeupdate() {
    this.correctEstimatedEnd()
  }

  @on('seeked')
  onSeeked() {
    this.setRealEnd()
  }

  constructor(player: TPlayer, options: any) {
    super(player, options)

    /* Value from this component is used by reference in live seek bar and live time */
    ;(player as any).liveDuration = this
  }

  correctEstimatedEnd() {
    /*
     * This updates estimatedEnd by
     * adding played time between func calls, while seekableEnd not updated.
     * EstimatedEnd is "smooth" / "analog" variant of seekableEnd.
     */
    if (!this.isRewound) return // needed only while rewound

    const seekableEnd = this.player_.seekable()?.end(0)

    if (seekableEnd !== this.seekableEnd) return this.setRealEnd()

    const now = performance.now() / 1000
    if (this.lastUpdateTime) {
      const delta = now - this.lastUpdateTime
      this.estimatedEnd += delta
    }

    this.lastUpdateTime = now
  }

  setRealEnd() {
    this.seekableEnd = this.player_.seekable()?.end(0)
    this.estimatedEnd = this.seekableEnd ?? 0
  }
}

LiveDuration.options = {
  createEl: false,
}
