import {
  type Cmcd,
  CmcdObjectType,
  CmcdStreamingFormat,
  CmcdStreamType,
} from '@svta/common-media-library'
import type { TPlayer } from 'video.js'

/**
 * Collector of CMCD data from the player,
 * based on https://github.com/montevideo-tech/videojs-cmcd/blob/main/src/CmcdData.js
 */
export class CmcdDataCollector {
  vhs?: any

  constructor(public player: TPlayer) {
    this.vhs = player.tech_.vhs
  }

  getEncodedBitrate(): number | undefined {
    try {
      const bandwidth =
        this.player.tech_.vhs.playlists.media_.attributes.BANDWIDTH

      const encodedBitrate = Math.round(bandwidth / 1000)

      return encodedBitrate
    } catch (e) {
      return undefined
    }
  }

  getObjectDuration(uriBeingRequested: string): number | undefined {
    try {
      const playlist = this.player.tech_.vhs.playlists.media()

      const currentSegmentIndex = playlist.segments.findIndex(
        (segment) => segment.resolvedUri === uriBeingRequested
      )

      const currentSegmentDuration = Math.round(
        playlist.segments[currentSegmentIndex].duration * 1000
      )

      return currentSegmentDuration
    } catch (error) {
      return undefined
    }
  }

  getObjectType(uriBeingRequested: string): CmcdObjectType | undefined {
    try {
      const extension = uriBeingRequested.split('.').pop()

      const { MANIFEST, AUDIO, VIDEO, MUXED, CAPTION, OTHER } = CmcdObjectType
      const supportedExtensions = {
        // Manifest or playlist
        m3u8: MANIFEST,
        mpd: MANIFEST,
        xml: MANIFEST,
        // Audio Only
        m4a: AUDIO,
        amp3ac: AUDIO,
        aac: AUDIO,
        caf: AUDIO,
        flac: AUDIO,
        oga: AUDIO,
        wav: AUDIO,
        // Video Only
        opus: VIDEO,
        ogv: VIDEO,
        mp4: VIDEO,
        mov: VIDEO,
        m4v: VIDEO,
        mkv: VIDEO,
        webm: VIDEO,
        ogg: VIDEO,
        flv: VIDEO,
        // Muxed audio and video
        ts: MUXED,
        // Init segment not implemented
        // Caption
        webvtt: CAPTION,
        vtt: CAPTION,
        // ISOBMFF timed text track not implemented
        // Cryptographic key, license or certificate not implemented
        // Other
        jpg: OTHER,
        jpeg: OTHER,
        gif: OTHER,
        png: OTHER,
        svg: OTHER,
        webp: OTHER,
      }

      if (extension && supportedExtensions.hasOwnProperty(extension)) {
        return supportedExtensions[extension]
      }
      return undefined
    } catch (error) {
      return undefined
    }
  }

  getTopBitrate(): number | undefined {
    try {
      const qualitylevels = this.player.qualityLevels?.()?.levels_

      if (!qualitylevels) return

      const highestBitrate = qualitylevels.reduce(function (prev, current) {
        return prev && prev.bitrate > current.bitrate ? prev : current
      })

      const topBitrate = Math.round(highestBitrate.bitrate / 1000)

      return topBitrate
    } catch (error) {
      return undefined
    }
  }

  bufferLengthMs(): number | undefined {
    try {
      let bufferLength = 0
      const tech = this.player.tech_
      const buffered = tech.buffered()

      if (buffered.length > 0) {
        const lastBufferedTime = buffered.end(buffered.length - 1)

        if (!isNaN(lastBufferedTime)) {
          bufferLength = lastBufferedTime - tech.currentTime()
        }
      }

      const bufferLengthMs = bufferLength * 1000

      return bufferLengthMs
    } catch (e) {
      return undefined
    }
  }

  getBufferLength(): number | undefined {
    // This key SHOULD only be sent with an object type of ‘a’, ‘v’ or ‘av’.
    try {
      const bufferLengthMs = this.bufferLengthMs()

      return bufferLengthMs
    } catch (e) {
      return undefined
    }
  }

  getDeadline(): number | undefined {
    try {
      const bufferLength = this.bufferLengthMs() ?? 0
      const playbackRate = (this.player.playbackRate() ?? 1) * 1000
      const deadline = Math.round(bufferLength / playbackRate)

      return deadline
    } catch (e) {
      return undefined
    }
  }

  getMeasuredThroughput(): number | undefined {
    try {
      const bandwidth = Math.round(this.vhs.systemBandwidth / 1000)

      return bandwidth
    } catch (e) {
      return undefined
    }
  }

  getNextObjectRequest(actualURIrequest: string): string | undefined {
    try {
      let nextObject

      if (
        this.player.duration()?.toString() !== 'Infinity' &&
        this.player.duration() !== 0
      ) {
        // This logic doesn't work when is live video
        const segments = this.vhs.playlists.media().segments
        const segmentIndexFind = segments.findIndex(
          (seg) => seg.resolvedUri === actualURIrequest
        )

        if (segmentIndexFind !== -1 && segmentIndexFind !== segments.length) {
          nextObject = segments[segmentIndexFind + 1].uri
        }
      }

      return nextObject
    } catch (e) {
      return undefined
    }
  }

  getNextRangeRequest(): string | undefined {
    // TODO
    return undefined
  }

  generateHashCode(string: string): number | undefined {
    let hash = 0

    if (string.length === 0) {
      return hash
    }
    for (let i = 0; i < string.length; i++) {
      const chr = string.charCodeAt(i)

      hash = (hash << 5) - hash + chr
      hash |= 0
    }
    return hash
  }

  getContentId(src: string): string | undefined {
    try {
      const cid = this.generateHashCode(src)
      return cid?.toString()
    } catch (e) {
      return undefined
    }
  }

  getPlaybackRate(): number | undefined {
    try {
      const rate = this.player.playbackRate()

      if (rate === 1) {
        return 1
      } else if (rate === 0) {
        return 0
      }
      return 2
    } catch (e) {
      return undefined
    }
  }

  getStreamingFormat(): CmcdStreamingFormat | undefined {
    try {
      const type = this.player.currentType()

      if (type === 'application/dash+xml') {
        return CmcdStreamingFormat.DASH
      } else if (type === 'application/x-mpegURL') {
        return CmcdStreamingFormat.HLS
      } else if (type === 'application/vnd.ms-sstr+xml') {
        return CmcdStreamingFormat.SMOOTH
      }
      return CmcdStreamingFormat.OTHER
    } catch (e) {
      return undefined
    }
  }

  getStreamType(): CmcdStreamType | undefined {
    try {
      // It is a live video
      if (
        this.player.duration()?.toString() === 'Infinity' ||
        this.player.duration() === 0
      ) {
        return CmcdStreamType.LIVE
      }
      // else it is a vod video
      return CmcdStreamType.VOD
    } catch (e) {
      return undefined
    }
  }

  getVersion(): number | undefined {
    return 1
  }

  getBufferStarvation(isWaitingEvent: boolean): boolean | undefined {
    try {
      return isWaitingEvent
    } catch (e) {
      return undefined
    }
  }

  getRequestedMaximumThroughput(): number | undefined {
    // TODO
    return undefined
  }

  collect({
    uri,
    src,
    waiting,
    cid,
    sid,
  }: {
    uri: string
    src: string
    waiting: boolean
    cid?: string
    sid?: string
  }): Cmcd {
    const res: Cmcd = {
      br: this.getEncodedBitrate(),
      d: this.getObjectDuration(uri),
      ot: this.getObjectType(uri),
      tb: this.getTopBitrate(),
    }

    if (
      res.ot === CmcdObjectType.AUDIO ||
      res.ot === CmcdObjectType.VIDEO ||
      res.ot === CmcdObjectType.MUXED
    ) {
      res.bl = this.getBufferLength()
    }

    res.dl = this.getDeadline()
    res.mtp = this.getMeasuredThroughput()
    res.nor = this.getNextObjectRequest(uri)
    res.nrr = this.getNextRangeRequest()
    if (waiting !== false) {
      res.su = waiting
    }

    res.cid = cid || this.getContentId(src)
    if (this.getPlaybackRate() !== 1) {
      res.pr = this.getPlaybackRate()
    }
    res.sf = this.getStreamingFormat()
    res.sid = sid
    res.st = this.getStreamType()
    if (this.getVersion() !== 1) {
      res.v = this.getVersion()
    }

    if (waiting !== false) {
      res.bs = this.getBufferStarvation(waiting)
    }
    res.rtp = this.getRequestedMaximumThroughput()

    return res
  }
}
