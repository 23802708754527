import videojs, { type TPlayer } from 'video.js'
import { Events } from '|>/shared/events'
import {
  a,
  appendContent,
  br,
  button,
  canvas,
  div,
  emptyEl,
  insertContent,
  pre,
  textContent,
} from '|>/shared/h'
import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'
import {
  compareQualityLevels,
  getBufferedTimeRanges,
  getEncodedBitrate,
  getMeasuredThroughput,
  getNetworkState,
  getPlaybackStatus,
  getReadyState,
  selectQualityLevel,
} from './lib'

import './debug-overlay.css'

/**
 * Maximum number of lines in the log history
 */
const MAX_LOG_LINES = 500

/*
 * Debug overlay, shows various information about the current state of the video player
 * Ideas: https://hlsjs.video-dev.org/demo/
 *        https://videojs-http-streaming.netlify.app/
 */

@register
export class DebugOverlay extends BaseComponent {
  isHidden = true

  // original trigger method
  declare originalTrigger: TPlayer['trigger']

  // log history
  private log: string[] = []
  private lastLogSize = 0

  // original log level, which was set before the overlay was shown
  declare originalLogLevel: Parameters<typeof videojs.log.level>[0]

  // interval id for updating the log history
  declare logIntervalId: number

  // whether to auto scroll the log to the bottom
  private logAutoScroll = true

  // quality levels history
  private levelsHistory: number[] = []
  private lastAdded: number | undefined
  private levels: any[] | null = null

  // throughput history
  private throughputHistory: number[] = []
  private lastAddedThroughput: number | undefined

  declare srcEl: HTMLDivElement
  declare statusEl: HTMLPreElement
  declare networkEl: HTMLDivElement
  declare qualityLevelsEl: HTMLCanvasElement
  declare qualityLevelsLegendEl: HTMLDivElement
  declare logEl: HTMLDivElement
  declare logToTopEl: HTMLButtonElement
  declare logToBottomEl: HTMLButtonElement

  constructor(player: TPlayer, options: any) {
    super(player, options)

    this.player_.trigger(Events.Controls.RegisterHotkey, [
      [['d', 'e', 'b', 'u', 'g'], () => this.toggle()],
    ])

    // store the original trigger method
    this.originalTrigger = this.player_.trigger

    // add scroll event listener on log element
    // to enable or disable auto-scroll
    videojs.on(this.logEl, 'scroll', () => {
      const { scrollTop, scrollHeight, clientHeight } = this.logEl
      const isAtBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 1
      this.logAutoScroll = isAtBottom
    })

    // scroll to the top of the log
    videojs.on(this.logToTopEl, 'click', () => {
      this.logEl.scrollTop = 0
      this.logAutoScroll = false
    })

    // scroll to the bottom of the log
    videojs.on(this.logToBottomEl, 'click', () => {
      this.logEl.scrollTop = this.logEl.scrollHeight
      this.logAutoScroll = true
    })

    // hide by default
    this.hide()
  }

  override createEl() {
    // prettier-ignore
    return div([
      (this.srcEl = div('.vjs-x-debug-overlay-src')),
      div('.vjs-x-debug-overlay-info', [
        div('.vjs-x-debug-overlay-info-left', [
          (this.statusEl = pre('.vjs-x-debug-overlay-status')),
          (this.networkEl = div('.vjs-x-debug-overlay-network')),
        ]),
        div('.vjs-x-debug-overlay-info-right', [
          (this.qualityLevelsEl = canvas('.vjs-x-debug-overlay-quality-levels', { width: 450, height: 150 })),
          (this.qualityLevelsLegendEl = div('.vjs-x-debug-overlay-quality-levels-legend')),
        ])
      ]),
      div('.vjs-x-debug-overlay-log-wrapper', [
        (this.logEl = div('.vjs-x-debug-overlay-log')),
        (this.logToTopEl = button('.vjs-x-debug-overlay-log-to-top', { type: 'button' }, '↑')),
        (this.logToBottomEl = button('.vjs-x-debug-overlay-log-to-bottom', { type: 'button' }, '↓')),
      ]),
    ])
  }

  /**
   * Toggle visibility of the debug overlay
   */
  toggle() {
    this[this.isHidden ? 'show' : 'hide']()
  }

  /**
   * Show the debug overlay
   */
  override show() {
    super.show()
    this.isHidden = false
    this.start()
  }

  /**
   * Hide the debug overlay
   */
  override hide() {
    super.hide()
    this.isHidden = true
    this.stop()
  }

  /**
   * Start updating the debug overlay
   */
  start() {
    // override `trigger` method
    if (this.player_.trigger === this.originalTrigger) {
      const self = this
      this.player_.trigger = function (event, data) {
        const type = typeof event === 'string' ? event : event.type
        const payload = typeof event === 'string' ? data : event.metadata
        self.handleEvent(type, payload)
        self.originalTrigger.apply(self.player_, arguments as any)
      }
    }

    // manually trigger the load event to get current source
    this.requestNamedAnimationFrame('DebugOverlay#updateSource', () => {
      this.handleEvent(Events.Media.Load, this.player_.currentSource())
    })

    // manually update status, network and quality levels
    this.requestNamedAnimationFrame('DebugOverlay#updateStatus', () => {
      this.updateStatus()
      this.updateNetwork()
      this.updateQualityLevels()
    })

    // update quality levels
    this.requestNamedAnimationFrame(
      'DebugOverlay#updateQualityLevels',
      this.updateQualityLevels
    )

    // store the original log level, and set it to 'all'
    // so it will log everything while the overlay is shown
    this.originalLogLevel = videojs.log.level() as typeof this.originalLogLevel
    videojs.log.level('all')

    // update log history every 500ms
    this.log = []
    emptyEl(this.logEl)
    this.logIntervalId = this.setInterval(this.updateLog, 500)
  }

  /**
   * Stop updating the debug overlay
   */
  stop() {
    // restore the original `trigger` method
    if (this.player_.trigger !== this.originalTrigger) {
      this.player_.trigger = this.originalTrigger
    }

    // stop updating quality levels
    this.cancelNamedAnimationFrame('DebugOverlay#updateQualityLevels')
    this.requestNamedAnimationFrame(
      'DebugOverlay#clearQualityLevels',
      this.clearQualityLevels
    )

    // restore the original log level
    videojs.log.level(this.originalLogLevel ?? 'info')

    // clear the log interval
    this.clearInterval(this.logIntervalId)

    // clear the log history
    this.log = []
    emptyEl(this.logEl)
  }

  /**
   * Handle ANY player's events
   * (but only player's events, not plugins', nor techs')
   */
  handleEvent(type: string, payload: any) {
    switch (type) {
      case Events.Media.Load:
        this.requestNamedAnimationFrame('DebugOverlay#updateSource', () => {
          this.updateSource(payload)
        })
        break

      case 'suspend':
      case 'loadedmetadata':
      case 'loadeddata':
      case 'durationchange':
      case 'liveedgechange':
      case 'timeupdate':
      case 'progress':
      case 'stalled':
      case 'abort':
      case 'canplay':
      case 'canplaythrough':
      case 'seeking':
      case 'seeked':
      case 'play':
      case 'playing':
      case 'pause':
      case 'ended':
      case 'waiting':
      case 'error':
        this.requestNamedAnimationFrame('DebugOverlay#updateStatus', () => {
          this.updateStatus()
          this.updateNetwork()
        })
        this.requestNamedAnimationFrame(
          'DebugOverlay#updateQualityLevels',
          this.updateQualityLevels
        )
        break
    }
  }

  /**
   * Update status of the current source of the video
   */
  updateSource(src: Events.Media.Load) {
    if (src && !this.isHidden) {
      const url = src.src ?? 'undefined source'
      const type = src.type ?? 'undefined'

      // format from https://github.com/videojs/videojs-contrib-eme
      let drmType = ''
      let drmUrl = ''
      if (
        'keySystems' in src &&
        src.keySystems != null &&
        typeof src.keySystems === 'object'
      ) {
        if ('com.widevine.alpha' in src.keySystems) {
          drmType = `widevine`
          drmUrl = src.keySystems['com.widevine.alpha']['url']
        } else if ('com.microsoft.playready' in src.keySystems) {
          drmType = `playready`
          drmUrl = src.keySystems['com.microsoft.playready']['url']
        } else if ('com.apple.fps' in src.keySystems) {
          drmType = `fairplay`
          drmUrl = src.keySystems['com.apple.fps']['url']
        } else if ('com.apple.fps.1_0' in src.keySystems) {
          drmType = `fairplay`
          drmUrl = src.keySystems['com.apple.fps.1_0']['url']
        }
      }

      insertContent(this.srcEl, [
        a({ href: url }, url), // source url
        br(),
        `type: ${type}`, // source type
        br(),
        drmType ? `${drmType}: ` : null, // drm info
        drmType ? a({ href: drmUrl }, drmUrl.replace(/\?.+/, '?...')) : null, // drm info
      ])
    }
  }

  /**
   * Update playback status of the video
   */
  updateStatus() {
    if (this.isHidden) return

    const player = this.player_
    const status = getPlaybackStatus(player)
    const time = player.currentTime().toFixed(2)
    const duration = player.duration().toFixed(2)
    const rate = player.playbackRate()
    const buffered = getBufferedTimeRanges(player)

    textContent(
      this.statusEl,
      `status: ${status.toUpperCase()}\n` + // playback status
        `time: ${time} / ${duration}\n` + // current time / duration
        `rate: ${rate}x\n` + // playback rate
        `buffered: ${buffered}` // buffered time ranges
    )
  }

  /**
   * Update network related information
   */
  updateNetwork() {
    if (this.isHidden) return

    const player = this.player_
    const network = getNetworkState(player)
    const ready = getReadyState(player)

    let throughput = getMeasuredThroughput(player)
    let bitrate = getEncodedBitrate(player)
    throughput = throughput ? Math.round(throughput / 1000) : undefined
    bitrate = bitrate ? Math.round(bitrate / 1000) : undefined

    insertContent(this.networkEl, [
      div(`network: ${network}`), // network state
      div(`ready: ${ready}`), // ready state
      div(`throughput: ${throughput ?? '-'}kbps`), // throughput
      div(`bitrate: ${bitrate ?? '-'}kbps`), // encoded bitrate
    ])

    // const quality = getQualityLevels(player)
    const qualityLevels = player.qualityLevels()
    const levels = qualityLevels.levels_.slice()
    const selected = levels[qualityLevels.selectedIndex]
    levels.sort((a, b) => a.bitrate - b.bitrate)

    if (levels.length > 0) {
      appendContent(this.networkEl, [
        div(`quality: `, [
          levels.map((level) =>
            button(`${level.height}p`, {
              type: 'button',
              style: {
                color: level === selected ? 'lightgreen' : 'green',
              },
              onclick: () => selectQualityLevel(levels, level),
              title:
                `id: ${level.id}\n` +
                `label: ${level.label}\n` +
                `bitrate: ${level.bitrate}\n` +
                `frameRate: ${level.frameRate}\n` +
                `height: ${level.height}\n` +
                `width: ${level.width}`,
            })
          ),
        ]),
      ])
    }
  }

  /**
   * Update quality levels graph
   */
  updateQualityLevels() {
    if (this.isHidden) return

    const player = this.player_
    const src = player.currentSource()
    if (src == null || !src.src) return

    const qualityLevels = player.qualityLevels()
    const levels = qualityLevels.levels_.slice()
    if (levels.length === 0) return // no quality levels available
    levels.sort((a, b) => b.bitrate - a.bitrate)

    const ctx = this.qualityLevelsEl.getContext('2d')
    if (!ctx) return

    // clear the canvas
    ctx.clearRect(0, 0, 450, 150)

    //
    // draw X axis
    //

    ctx.lineWidth = 1
    ctx.strokeStyle = 'white'
    ctx.fillStyle = 'white'
    ctx.font = '10px monospace'
    ctx.beginPath()
    ctx.moveTo(0, 130)
    ctx.lineTo(420, 130)
    ctx.stroke()

    //
    // draw seconds on X axis
    //

    // scale is 30px per second
    const seconds_x: number[] = []
    let scale = 30 / 1000
    let ms = Date.now()
    let s = Math.trunc(ms / 1000) % 60
    let d = ms % 1000
    for (let i = 0; i < 14; i += 1) {
      const t = 420 - d * scale
      seconds_x.push(t)

      ctx.beginPath()
      ctx.moveTo(t, 130)
      ctx.lineTo(t, 133)
      ctx.stroke()
      ctx.fillText(`${s}`, t - (s < 10 ? 3 : 6), 145)

      d += 1000
      if (--s < 0) s += 60
    }
    seconds_x.reverse()

    // clamp the top and bottom Y values
    const topY = Math.max(15_000_000, levels[0].bitrate + 100000)
    const bottomY = Math.min(
      500_000,
      levels[levels.length - 1].bitrate - 100000
    )

    // use logarithmic scale for the Y axis
    const logTopY = Math.log(topY)
    const logBottomY = Math.log(bottomY)
    const scaleY = 130 / (logTopY - logBottomY)

    //
    // draw throughput history
    //

    if (
      this.lastAddedThroughput == null ||
      ms - this.lastAddedThroughput > 25
    ) {
      const throughput = getMeasuredThroughput(player)
      this.throughputHistory.push(throughput ? Math.log(throughput) : 0)
      this.throughputHistory = this.throughputHistory.slice(-420) // keep last 420 items
      this.lastAddedThroughput = ms
    }

    ctx.strokeStyle = 'rgba(0, 0, 150, 0.4)'
    for (let i = 0; i < 420; i++) {
      const y = this.throughputHistory.at(-i - 1)
      if (y != null && y > 0) {
        ctx.beginPath()
        ctx.moveTo(420 - i, 130)
        ctx.lineTo(420 - i, 130 - (y - logBottomY) * scaleY)
        ctx.stroke()
      }
    }

    //
    // draw levels
    //

    const levels_y = levels.slice().fill(undefined)
    for (let i = 0; i < levels.length; i++) {
      const level = levels[i]
      const logBitrate = Math.log(level.bitrate)
      const y = 130 - (logBitrate - logBottomY) * scaleY
      levels_y[i] = y

      ctx.strokeStyle = level.enabled ? 'lightgreen' : 'green'
      ctx.beginPath()
      ctx.moveTo(0, y)
      ctx.lineTo(420, y)
      ctx.stroke()
    }

    //
    // draw selected level
    //

    const selectedIndex = qualityLevels.selectedIndex
    const selected = qualityLevels.levels_[selectedIndex]
    const index = levels.findIndex((level) => level === selected)

    s = Math.trunc(ms / 1000) % 60
    if (this.lastAdded == null || this.lastAdded !== s) {
      this.levelsHistory.push(index ?? -1)
      this.levelsHistory = this.levelsHistory.slice(-14) // keep last 14 items
      this.lastAdded = s
    }

    ctx.strokeStyle = 'red'
    ctx.lineWidth = 3
    ctx.beginPath()
    ctx.moveTo(420, levels_y[index])
    for (let i = 0; i < 14; i++) {
      const index = this.levelsHistory.at(-i - 1)
      const x = seconds_x.at(-i - 1)
      const y = levels_y[index ?? -1]
      if (x != null && y != null) {
        ctx.lineTo(x, y)
      }
    }
    ctx.stroke()

    //
    // draw labels for each level
    //

    if (!compareQualityLevels(this.levels, levels)) {
      insertContent(
        this.qualityLevelsLegendEl,
        levels
          .toReversed()
          .map((level, i) => {
            const bitrate = Math.round(level.bitrate / 1000)
            const height = level.height
            return button(`${bitrate}kbps|${height}p`, {
              type: 'button',
              style: {
                top: `calc(${levels_y[levels.length - i - 1]}px - 7px)`,
              },
              onclick: () => selectQualityLevel(levels, level),
              title:
                `id: ${level.id}\n` +
                `label: ${level.label}\n` +
                `bitrate: ${level.bitrate}\n` +
                `frameRate: ${level.frameRate}\n` +
                `height: ${level.height}\n` +
                `width: ${level.width}`,
            })
          })
          .concat(
            button('.vjs-x-debug-overlay-quality-levels-legend-auto', 'auto', {
              type: 'button',
              onclick: () => selectQualityLevel(levels, undefined),
            })
          )
      )
      this.levels = levels
    }

    // update graph on a next frame
    if (!this.isHidden) {
      this.requestNamedAnimationFrame(
        'DebugOverlay#updateQualityLevels',
        this.updateQualityLevels
      )
    }
  }

  /**
   * Clear quality levels graph and legend
   */
  clearQualityLevels() {
    this.levelsHistory = []
    this.lastAdded = undefined
    this.levels = null

    emptyEl(this.qualityLevelsLegendEl)

    const ctx = this.qualityLevelsEl.getContext('2d')
    if (!ctx) return

    // clear the canvas
    ctx.clearRect(0, 0, 450, 150)
  }

  /**
   * Update the log history
   */
  updateLog() {
    if (this.isHidden) return

    const player = this.player_
    const src = player.currentSource()
    if (src == null || !src.src) return

    let log = videojs.log.history() || []

    // skip updating the log if it didn't change
    if (log.length === 0) return
    if (this.lastLogSize === log.length && log.length < 1000) return
    this.lastLogSize = log.length

    // format the log history as array of strings
    // prettier-ignore
    log = log.map((line) => line.map((item) =>
      typeof item === 'string' ? item : JSON.stringify(item)
    ).join(' '))

    // try to find a place where the new log lines starts
    //
    // there is no any indexes in the log history, so we need to find a place where the new lines start.
    // in order to do this, I check the last 3 lines of the local log history
    // and then find a place where the same lines appears in the new log history
    //
    // it is not possible to save indexes because videojs log history is truncated to 1000 lines

    // prettier-ignore
    const [x, y, z] = [undefined, undefined, undefined, ...this.log.slice(-3)].slice(-3)
    let index = 0
    if (z != null) {
      index = log.findIndex(
        (_, i, all) => all[i] === z && all[i - 1] === y && all[i - 2] === x
      )
    }

    // append new lines to the local log history
    const newLog = log.slice(index + 1)
    if (newLog.length > 0) {
      Array.prototype.push.apply(this.log, newLog)
      if (this.log.length > MAX_LOG_LINES) {
        this.log.splice(0, this.log.length - MAX_LOG_LINES)
      }

      // append only new lines to the output
      appendContent(
        this.logEl,
        newLog.map((line) => pre(line))
      )

      // remove first children so the log doesn't grow infinitely
      while (this.logEl.children.length > MAX_LOG_LINES) {
        this.logEl.removeChild(this.logEl.firstChild!)
      }

      // scroll to the bottom of the log
      if (this.logAutoScroll) {
        this.logEl.scrollTop = this.logEl.scrollHeight
      }
    }
  }

  /**
   * Dispose component, restore the original `trigger` method
   */
  override dispose() {
    this.isHidden = true
    this.stop()
    super.dispose()
  }
}

DebugOverlay.options = {
  className: 'vjs-x-debug-overlay',
}
