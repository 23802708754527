import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'

import './live-unavailable-bar.css'

/**
 * Live seekable bar
 */
@register
export class LiveUnavailableBar extends BaseComponent {}

LiveUnavailableBar.options = {
  barName: 'liveUnavailableBar',
  className: 'vjs-x-live-unavailable-bar',
}
