import { useCallback, useEffect, useRef } from 'react'

/**
 * Hook to hold the reference to the callback function
 * Returns stable function reference that will not change between renders
 */
export function useEventCallback<T extends Function>(callback: T | undefined) {
  // hold the reference to the callback function
  // callback function can be unstable, it doesn't matter -
  // new callback will be saved in ref, and then wrapped in a stable function
  const ref = useRef(callback)

  useEffect(() => {
    ref.current = callback
  }, [callback])

  // create stable callback function wrapper
  return useCallback((...args: any[]) => ref.current?.(...args), []) as any as T
}
