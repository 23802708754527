import { Events } from '|>/shared/events'
import { bind_, throttle, UPDATE_REFRESH_INTERVAL } from '|>/shared/fn'
import { addClass, div, insertContent, removeClass } from '|>/shared/h'
import { absoluteTime, EMPTY_TIME } from '|>/shared/utils'
import { on, register } from '|>/shared/vjs'
import { BaseComponent } from '../base'

import './current-time-display.css'

@register
export class CurrentTimeDisplay extends BaseComponent {
  enabled_: boolean = false
  updateInterval: number | undefined

  constructor(player) {
    super(player)

    this.updateForced = bind_(this, this.update)
    this.update = throttle(this.updateForced, 500)

    this.player_.on('error', () => {
      this.disable()
      this.setEmptyTime()
    })
  }

  override createEl(): Element {
    return div('.empty-time', EMPTY_TIME)
  }

  @on(Events.Media.Load)
  updateSource() {
    this.setEmptyTime()
  }

  @on(Events.Media.ProgressBarChanged)
  updateBoundaries(_boundaries: Events.Media.ProgressBarChanged) {
    // overridden in subclasses
  }

  setEmptyTime() {
    insertContent(this.el_, EMPTY_TIME)
    addClass(this.el_, 'empty-time')
  }

  setTime(time?: number) {
    if (time) {
      insertContent(this.el_, `-${absoluteTime({ time, noTimezone: true })}`)
      removeClass(this.el_, 'empty-time')
    }
  }

  updateForced: typeof this.update
  update() {}

  enable() {
    if (this.enabled_) {
      return
    }

    if (!this.updateInterval) {
      this.updateInterval = this.setInterval(
        this.update,
        UPDATE_REFRESH_INTERVAL
      )
    }

    this.enabled_ = true
  }

  disable() {
    if (!this.enabled_) {
      return
    }

    if (this.updateInterval) {
      this.clearInterval(this.updateInterval)
      this.updateInterval = undefined
    }

    this.el().innerHTML = EMPTY_TIME
    this.el().classList.add('empty-time')

    this.enabled_ = false
  }
}
