import type { TPlayer } from 'video.js'
import { Events } from '|>/shared/events'
import {
  addClass,
  div,
  insertContent,
  removeAttribute,
  removeClass,
  setAttribute,
} from '|>/shared/h'
import { on, register } from '|>/shared/vjs'
import { BaseComponent } from '../base'

import './title.css'

/**
 * Currently playing video title and subtitle
 */
@register
export class Title extends BaseComponent {
  declare logo: Element
  declare title: Element
  declare subtitle: Element

  constructor(player: TPlayer, options?: Events.Media.TitleChanged) {
    super(player)

    // update title and subtitle on initialization from options, if any
    this.updateTitle(options)
  }

  override createEl(): Element {
    return div([
      (this.logo = div('.vjs-x-logo')),
      div([
        (this.title = div('.vjs-x-title')),
        (this.subtitle = div('.vjs-x-sub-title')),
      ]),
    ])
  }

  // update title and subtitle on title change event
  @on(Events.Media.TitleChanged)
  updateTitle({ title, subtitle, logo }: Events.Media.TitleChanged = {}) {
    insertContent(this.title, title || '')
    insertContent(this.subtitle, subtitle || '')

    if (logo) {
      removeClass(this.logo, 'vjs-x-logo-hidden')
      setAttribute(this.logo, 'style', `background-image: url(${logo})`)
    } else {
      addClass(this.logo, 'vjs-x-logo-hidden')
      removeAttribute(this.logo, 'style')
    }
  }
}

Title.options = {
  className: 'vjs-x-title-block',
}
