import { div } from '|>/shared/h'
import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'

import './chrome-casting-message.css'

@register
export class ChromeCastingMessage extends BaseComponent {
  declare titleEl: HTMLDivElement

  constructor(player, options) {
    super(player, options)

    this.hide()

    this.player_.on('chromecastConnected', () => {
      this.show()
    })

    this.player_.on('chromecastDisconnected', () => {
      this.hide()
    })
  }

  createTitle(deviceName: string) {
    return `${this.localize('casting_status', [`"${deviceName}"`])} ${this.localize('casting_activated')}.`
  }

  updateTitle(deviceName: string) {
    if (this.titleEl) this.titleEl.innerText = this.createTitle(deviceName)
  }

  override createEl() {
    const title = this.createTitle('')
    const descriptionText = `${this.localize('please_tap_button')} `
    const stopCastingMessage = ` ${this.localize('stop_casting_message')}`

    this.titleEl = div('.vjs-x-casting-message-title', null, title)

    const iconEl = div('.vjs-x-casting-message-icon.vjs-icon-placeholder')

    const firstLineEl = div(
      '.vjs-x-casting-message-description-first-line',
      null,
      [descriptionText, iconEl]
    )

    const descriptionEl = div('.vjs-x-casting-message-description', null, [
      firstLineEl,
      stopCastingMessage,
    ])

    return div('.vjs-x-casting-message', null, [this.titleEl, descriptionEl])
  }
}

ChromeCastingMessage.options = {
  className: 'vjs-x-casting-message',
}
