import videojs from 'video.js'

export const createChromecastButton = () => {
  const SilvermineChromecastButton = videojs.getComponent('chromecastButton')

  class ChromecastButton extends SilvermineChromecastButton {
    constructor(player, options) {
      super(player, options)

      this.on('mouseover', this.handleMouseOver)
      this.on('mouseout', this.handleMouseOut)
    }

    handleMouseOver() {
      this.player_.addClass('vjs-chromecast-button')
    }

    handleMouseOut() {
      this.player_.removeClass('vjs-chromecast-button')
    }
  }

  videojs.registerComponent('chromecastButton', ChromecastButton)
}
