import { register } from '|>/shared/vjs'
import { BaseMenu } from '../../base'
import type { SettingsMenuContainer } from '../settings-menu-container'
import { SettingsMainMenuAudioItem } from './settings-main-menu-audio-item'
import { SettingsMainMenuSubtitlesItem } from './settings-main-menu-subtitles-item'

/**
 * Settings main menu with audio and subtitles items
 */
@register
export class SettingsMainMenu extends BaseMenu {
  declare container: SettingsMenuContainer | undefined

  // menu items
  declare audioItem: SettingsMainMenuAudioItem
  declare subtitlesItem: SettingsMainMenuSubtitlesItem

  update() {
    this.audioItem.update()
    this.subtitlesItem.update()
    this.audioItem.container = this.container
    this.subtitlesItem.container = this.container
  }

  activate() {
    this.addClass('vjs-x-active')
  }

  deactivate() {
    this.removeClass('vjs-x-active')
  }
}

SettingsMainMenu.options = {
  className: 'vjs-x-settings-main-menu vjs-menu',
  children: [
    // subtitles menu item
    SettingsMainMenuSubtitlesItem.as('subtitlesItem'),

    // audio menu item
    SettingsMainMenuAudioItem.as('audioItem'),
  ],
}
