import { Events } from '|>/shared/events'
import { register } from '|>/shared/vjs'
import { CurrentTimeDisplay } from './current-time-display'

@register
export class VodCurrentTimeDisplay extends CurrentTimeDisplay {
  override updateBoundaries(boundaries: Events.Media.ProgressBarChanged) {
    if (boundaries === undefined) {
      this.enable()

      this.updateForced() // force update
    }
  }

  override update() {
    // ignore updates while the tab is hidden
    // TODO: add listener for visibility change -> this.on(document, 'visibilitychange', this.toggleVisibility_)
    if (document.visibilityState === 'hidden') return

    const currentTime = this.player_.currentTime()
    const duration = this.player_.duration()

    const timeToEndMs = (duration - currentTime) * 1000

    this.setTime(timeToEndMs)
  }
}

VodCurrentTimeDisplay.options = {
  className: 'vjs-x-current-time-display vjs-x-vod-current-time-display',
}
