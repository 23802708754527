import { Events } from '|>/shared/events'
import { div, li, span, textContent } from '|>/shared/h'
import { on, register } from '|>/shared/vjs'
import { BaseMenuItem } from '../../base'
import { LocalEvents } from '../local-events'
import { normalizeTracksTitles } from '../normalize-tracks-titles'
import type { SettingsMenuContainer } from '../settings-menu-container'

/**
 * Audio menu item in settings main menu
 */
@register
export class SettingsMainMenuAudioItem extends BaseMenuItem {
  declare container: SettingsMenuContainer | undefined

  declare activeTrackEl_: HTMLSpanElement
  declare labelEl_: HTMLSpanElement

  override createEl() {
    return li('.vjs-menu-item.vjs-x-menu-audio-item', [
      span('.vjs-icon-placeholder', { ariaHidden: 'true' }),
      (this.labelEl_ = span(
        '.vjs-menu-item-text',
        this.localize(this.options_.label)
      )),
      (this.activeTrackEl_ = div('.vjs-x-active-track')),
    ])
  }

  @on(Events.Media.AudioTrackChanged)
  handleSelectedTrack() {
    let label: string | null | undefined
    let selected = false
    const normalize = normalizeTracksTitles()
    const tracks = this.player_.audioTracks()
    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i]
      label = normalize(track.label || track.language || 'Unknown')
      if (track.enabled) {
        selected = true
        break
      }
    }
    if (tracks.length === 1) {
      selected = true
      label = this.localize('Default')
    }
    textContent(
      this.activeTrackEl_,
      selected ? label || '' : this.localize('audio off')
    )

    // force update title to respect locale
    textContent(this.labelEl_, this.localize(this.options_.label))
  }

  update() {
    this.handleSelectedTrack()
  }

  override handleLanguagechange() {
    this.update()
  }

  override handleClick() {
    this.container?.trigger(LocalEvents.SettingsActivateAudioMenu)
  }
}

SettingsMainMenuAudioItem.options = {
  label: 'Audio',
}
