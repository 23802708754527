import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'

import './loading-spinner.css'

@register
export class LoadingSpinner extends BaseComponent {}

LoadingSpinner.options = {
  className: 'vjs-loading-spinner',
}
