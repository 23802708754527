import { useEffect, useRef } from 'react'
import type { TPlayer } from 'video.js'
import type { EventType, EventTypeMap } from '|>/shared/events'

/**
 * Hook to trigger VideoJS player events
 */
export function useEventTrigger<T extends keyof EventTypeMap>(
  player: TPlayer | null | undefined,
  event: T,
  payload: EventType<T>,
  reason: any[]
): void {
  const payloadRef = useRef(payload)

  useEffect(() => {
    payloadRef.current = payload
  }, [payload])

  // trigger the event with the payload when the any reason (or player) changes
  useEffect(() => {
    player?.trigger(event, payloadRef.current)
  }, [player, ...reason])
}
