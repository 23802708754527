import videojs from 'video.js'
import th from 'video.js/dist/lang/th.json'

videojs.addLanguage('th', th)
videojs.addLanguage('th', {
  'Error': 'ข้อผิดพลาด',
  'Error Code': 'รหัสข้อผิดพลาด',

  // custom thai translations
  'subtitles off': 'ปิด',
  'captions off': 'ปิด',
  'audio off': 'ปิด',
  'Default': 'ค่าเริ่มต้น',
  'Unknown': 'ค่าเริ่มต้น',
})
