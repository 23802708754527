import { useMemo } from 'react'
import type { Configuration } from '|>/core'

/**
 * Hook to handle Player configuration
 * !This is NOT a VideoJS options!
 * Returns memoized options object, that will not change between renders
 */
export function useConfiguration({
  using,
  onReady,
  chromecast,
  cmcd,
}: Configuration) {
  return useMemo(() => {
    return {
      using,
      chromecast,
      cmcd,
      onReady,
    } satisfies Configuration
  }, [JSON.stringify(cmcd)])
}
