import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'
import { MouseTimeDisplay } from './mouse-time-display'
/**
 * This is "played / colored" progress line
 */
@register
export class LivePlayProgressBar extends BaseComponent {
  declare mouseTimeDisplay: MouseTimeDisplay
}

LivePlayProgressBar.options = {
  className: 'vjs-play-progress vjs-slider-bar vjs-x-live-play-progress',
  children: [MouseTimeDisplay.as('mouseTimeDisplay')],
}
