import { logger } from '|>/shared/logger'
import {
  fairplay as commonFairplay,
  playready as commonPlayready,
  widevine as commonWidevine,
} from './common'

const warn =
  (modifier: any) =>
  <T>(options: T): T => {
    logger.warn('DRM provider is not supported, it might not work')
    return modifier(options)
  }

// use common settings for unsupported provider
// + print warning
export const widevine = warn(commonWidevine)
export const playready = warn(commonPlayready)
export const fairplay = warn(commonFairplay)
