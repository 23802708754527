/**
 * Injects a script tag into the document head.
 */
export const addScriptAsync = (src: string): Promise<Event> => {
  return new Promise((resolve, reject) => {
    const element = document.createElement('script')
    element.setAttribute('src', src)

    element.addEventListener('load', resolve)
    element.addEventListener('error', () =>
      reject(new Error(`${src} script load fail`))
    )

    document.body.appendChild(element)
  })
}

export const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0
}
