import type { TPlayer } from 'video.js'
import { register } from '|>/shared/vjs'
import { BaseButton } from '../base'
import { LocalEvents } from './local-events'
import type { Settings } from './settings'

/**
 * Control bar button to open settings menu
 */
@register
export class SettingsButton extends BaseButton {
  declare controlText_: string | undefined
  declare settings: Settings

  pressed_: boolean | undefined

  constructor(player: TPlayer, options: any) {
    super(player, options)
    this.pressed(false)
  }

  override handleClick() {
    if (this.pressed()) {
      this.settings.menuContainer.trigger(LocalEvents.SettingsCloseMenu)
    } else {
      this.settings.menuContainer.update()
      this.settings.menuContainer.trigger(LocalEvents.SettingsActivateMainMenu)
    }
  }

  pressed(is?: boolean) {
    if (is == null) {
      return this.pressed_
    }
    this.pressed_ = is
    this.toggleClass('vjs-x-settings-button-pressed', is)
    this.player_.toggleClass('vjs-x-settings-button', is)
  }
}

SettingsButton.prototype.controlText_ = 'Settings'
SettingsButton.options = {
  className: 'vjs-x-settings-button',
}
