import type { Src } from 'video.js'
import videojs from 'video.js'
import { extend } from '|>/shared/vjs'

/**
 * Helper function to get VideoJS component by name,
 * returns `any` to avoid type errors, because `getComponent` always returns `Component`
 */
function baseComponent(id: string): any {
  const name = id.charAt(0).toLowerCase() + id.slice(1) // convert first letter to lowercase
  return extend(videojs.getComponent(id), id, name)
}

// prettier-ignore
export const BaseAudioTrackButton: Src.AudioTrackButton =
  baseComponent('AudioTrackButton')

// prettier-ignore
export const BaseAudioTrackMenuItem: Src.AudioTrackMenuItem =
  baseComponent('AudioTrackMenuItem')

// prettier-ignore
export const BaseBigPlayButton: Src.BigPlayButton =
  baseComponent('BigPlayButton')

// prettier-ignore
export const BaseButton: Src.Button =
  baseComponent('Button')

// prettier-ignore
export const BaseCaptionsButton: Src.CaptionsButton =
  baseComponent('CaptionsButton')

// prettier-ignore
export const BaseCaptionSettingsMenuItem: Src.CaptionSettingsMenuItem =
  baseComponent('CaptionSettingsMenuItem')

// prettier-ignore
export const BaseChaptersButton: Src.ChaptersButton =
  baseComponent('ChaptersButton')

// prettier-ignore
export const BaseChaptersTrackMenuItem: Src.ChaptersTrackMenuItem =
  baseComponent('ChaptersTrackMenuItem')

// prettier-ignore
export const BaseClickableComponent: Src.ClickableComponent =
  baseComponent('ClickableComponent')

// prettier-ignore
export const BaseCloseButton: Src.CloseButton =
  baseComponent('CloseButton')

// prettier-ignore
export const BaseComponent: Src.Component =
  baseComponent('Component')

// prettier-ignore
export const BaseControlBar: Src.ControlBar =
  baseComponent('ControlBar')

// prettier-ignore
export const BaseCurrentTimeDisplay: Src.CurrentTimeDisplay =
  baseComponent('CurrentTimeDisplay')

// prettier-ignore
export const BaseCustomControlSpacer: Src.CustomControlSpacer =
  baseComponent('CustomControlSpacer')

// prettier-ignore
export const BaseDescriptionsButton: Src.DescriptionsButton =
  baseComponent('DescriptionsButton')

// prettier-ignore
export const BaseDurationDisplay: Src.DurationDisplay =
  baseComponent('DurationDisplay')

// prettier-ignore
export const BaseErrorDisplay: Src.ErrorDisplay =
  baseComponent('ErrorDisplay')

// prettier-ignore
export const BaseFullscreenToggle: Src.FullscreenToggle =
  baseComponent('FullscreenToggle')

// prettier-ignore
export const BaseLiveDisplay: Src.LiveDisplay =
  baseComponent('LiveDisplay')

// prettier-ignore
export const BaseLiveTracker: Src.LiveTracker =
  baseComponent('LiveTracker')

// prettier-ignore
export const BaseLoadingSpinner: Src.LoadingSpinner =
  baseComponent('LoadingSpinner')

// prettier-ignore
export const BaseLoadProgressBar: Src.LoadProgressBar =
  baseComponent('LoadProgressBar')

// prettier-ignore
export const BaseMediaLoader: Src.MediaLoader =
  baseComponent('MediaLoader')

// prettier-ignore
export const BaseMenu: Src.Menu =
  baseComponent('Menu')

// prettier-ignore
export const BaseMenuButton: Src.MenuButton =
  baseComponent('MenuButton')

// prettier-ignore
export const BaseMenuItem: Src.MenuItem =
  baseComponent('MenuItem')

// prettier-ignore
export const BaseModalDialog: Src.ModalDialog =
  baseComponent('ModalDialog')

// prettier-ignore
export const BaseMouseTimeDisplay: Src.MouseTimeDisplay =
  baseComponent('MouseTimeDisplay')

// prettier-ignore
export const BaseMouseVolumeLevelDisplay: Src.MouseVolumeLevelDisplay =
  baseComponent('MouseVolumeLevelDisplay')

// prettier-ignore
export const BaseMuteToggle: Src.MuteToggle =
  baseComponent('MuteToggle')

// prettier-ignore
export const BaseOffTextTrackMenuItem: Src.OffTextTrackMenuItem =
  baseComponent('OffTextTrackMenuItem')

// prettier-ignore
export const BasePictureInPictureToggle: Src.PictureInPictureToggle =
  baseComponent('PictureInPictureToggle')

// prettier-ignore
export const BasePlaybackRateMenuButton: Src.PlaybackRateMenuButton =
  baseComponent('PlaybackRateMenuButton')

// prettier-ignore
export const BasePlaybackRateMenuItem: Src.PlaybackRateMenuItem =
  baseComponent('PlaybackRateMenuItem')

// prettier-ignore
export const BasePlayer: Src.Player =
  baseComponent('Player')

// prettier-ignore
export const BasePlayProgressBar: Src.PlayProgressBar =
  baseComponent('PlayProgressBar')

// prettier-ignore
export const BasePlayToggle: Src.PlayToggle =
  baseComponent('PlayToggle')

// prettier-ignore
export const BasePosterImage: Src.PosterImage =
  baseComponent('PosterImage')

// prettier-ignore
export const BaseProgressControl: Src.ProgressControl =
  baseComponent('ProgressControl')

// prettier-ignore
export const BaseRemainingTimeDisplay: Src.RemainingTimeDisplay =
  baseComponent('RemainingTimeDisplay')

// prettier-ignore
export const BaseResizeManager: Src.ResizeManager =
  baseComponent('ResizeManager')

// prettier-ignore
export const BaseSeekBar: Src.SeekBar =
  baseComponent('SeekBar')

// prettier-ignore
export const BaseSeekToLive: Src.SeekToLive =
  baseComponent('SeekToLive')

// prettier-ignore
export const BaseSkipBackward: Src.SkipBackward =
  baseComponent('SkipBackward')

// prettier-ignore
export const BaseSkipForward: Src.SkipForward =
  baseComponent('SkipForward')

// prettier-ignore
export const BaseSlider: Src.Slider =
  baseComponent('Slider')

// prettier-ignore
export const BaseSpacer: Src.Spacer =
  baseComponent('Spacer')

// prettier-ignore
export const BaseSubsCapsButton: Src.SubsCapsButton =
  baseComponent('SubsCapsButton')

// prettier-ignore
export const BaseSubsCapsMenuItem: Src.SubsCapsMenuItem =
  baseComponent('SubsCapsMenuItem')

// prettier-ignore
export const BaseSubtitlesButton: Src.SubtitlesButton =
  baseComponent('SubtitlesButton')

// prettier-ignore
export const BaseTech: Src.Tech =
  baseComponent('Tech')

// prettier-ignore
export const BaseTextTrackButton: Src.TextTrackButton =
  baseComponent('TextTrackButton')

// prettier-ignore
export const BaseTextTrackDisplay: Src.TextTrackDisplay =
  baseComponent('TextTrackDisplay')

// prettier-ignore
export const BaseTextTrackFieldset: Src.TextTrackFieldset =
  baseComponent('TextTrackFieldset')

// prettier-ignore
export const BaseTextTrackMenuItem: Src.TextTrackMenuItem =
  baseComponent('TextTrackMenuItem')

// prettier-ignore
export const BaseTextTrackSelect: Src.TextTrackSelect =
  baseComponent('TextTrackSelect')

// prettier-ignore
export const BaseTextTrackSettings: Src.TextTrackSettings =
  baseComponent('TextTrackSettings')

// prettier-ignore
export const BaseTextTrackSettingsColors: Src.TextTrackSettingsColors =
  baseComponent('TextTrackSettingsColors')

// prettier-ignore
export const BaseTextTrackSettingsFont: Src.TextTrackSettingsFont =
  baseComponent('TextTrackSettingsFont')

// prettier-ignore
export const BaseTimeDisplay: Src.TimeDisplay =
  baseComponent('TimeDisplay')

// prettier-ignore
export const BaseTimeDivider: Src.TimeDivider =
  baseComponent('TimeDivider')

// prettier-ignore
export const BaseTimeTooltip: Src.TimeTooltip =
  baseComponent('TimeTooltip')

// prettier-ignore
export const BaseTitleBar: Src.TitleBar =
  baseComponent('TitleBar')

// prettier-ignore
export const BaseTrackButton: Src.TrackButton =
  baseComponent('TrackButton')

// prettier-ignore
export const BaseTrackSettingsControls: Src.TrackSettingsControls =
  baseComponent('TrackSettingsControls')

// prettier-ignore
export const BaseTransientButton: Src.TransientButton =
  baseComponent('TransientButton')

// prettier-ignore
export const BaseVolumeBar: Src.VolumeBar =
  baseComponent('VolumeBar')

// prettier-ignore
export const BaseVolumeControl: Src.VolumeControl =
  baseComponent('VolumeControl')

// prettier-ignore
export const BaseVolumeLevel: Src.VolumeLevel =
  baseComponent('VolumeLevel')

// prettier-ignore
export const BaseVolumeLevelTooltip: Src.VolumeLevelTooltip =
  baseComponent('VolumeLevelTooltip')

// prettier-ignore
export const BaseVolumePanel: Src.VolumePanel =
  baseComponent('VolumePanel')
