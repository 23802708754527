import h from 'hyperscript'
import helpers from 'hyperscript-helpers'
import videojs from 'video.js'

// reexport default hyperscript function
export { h }

// export hyperscript helpers
export const hh = helpers(h)

// reexport commonly used hyperscript helpers as named exports
export const {
  a,
  abbr,
  acronym,
  address,
  applet,
  area,
  article,
  aside,
  audio,
  b,
  base,
  basefont,
  bdi,
  bdo,
  bgsound,
  big,
  blink,
  blockquote,
  body,
  br,
  button,
  canvas,
  caption,
  center,
  cite,
  code,
  col,
  colgroup,
  command,
  content,
  data,
  datalist,
  dd,
  del,
  details,
  dfn,
  dialog,
  dir,
  div,
  dl,
  dt,
  element,
  em,
  embed,
  fieldset,
  figcaption,
  figure,
  font,
  footer,
  form,
  frame,
  frameset,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  head,
  header,
  hgroup,
  hr,
  html,
  i,
  iframe,
  image,
  img,
  input,
  ins,
  isindex,
  kbd,
  keygen,
  label,
  legend,
  li,
  link,
  listing,
  main,
  map,
  mark,
  marquee,
  math,
  menu,
  menuitem,
  meta,
  meter,
  multicol,
  nav,
  nextid,
  nobr,
  noembed,
  noframes,
  noscript,
  object,
  ol,
  optgroup,
  option,
  output,
  p,
  param,
  picture,
  plaintext,
  pre,
  progress,
  q,
  rb,
  rbc,
  rp,
  rt,
  rtc,
  ruby,
  s,
  samp,
  script,
  section,
  select,
  shadow,
  slot,
  small,
  source,
  spacer,
  span,
  strike,
  strong,
  style,
  sub,
  summary,
  sup,
  svg,
  table,
  tbody,
  td,
  template,
  textarea,
  tfoot,
  th,
  thead,
  time,
  title,
  tr,
  track,
  tt,
  u,
  ul,
  // var, // not available because it's a reserved keyword
  video,
  wbr,
  xmp,
} = hh

// reexport commonly used videojs.dom functions as named exports
export const {
  addClass,
  appendContent,
  emptyEl,
  findPosition,
  getBoundingClientRect,
  getPointerPosition,
  insertContent,
  isSingleLeftClick,
  removeClass,
  textContent,
  setAttribute,
  removeAttribute,
} = videojs.dom
