import type { TSource } from 'video.js'

export function isDash(src: string | TSource): boolean {
  if (typeof src === 'string') {
    return src.includes('.mpd')
  }

  if (typeof src === 'object') {
    if (typeof src.src === 'string' && src.src.includes('.mpd')) {
      return true
    }

    if (
      typeof src.type === 'string' &&
      ['application/dash+xml'].includes(src.type.toLowerCase())
    ) {
      return true
    }
  }

  return false
}

export function isHls(src: string | TSource): boolean {
  if (typeof src === 'string') {
    return src.includes('.m3u8')
  }

  if (typeof src === 'object') {
    if (typeof src.src === 'string' && src.src.includes('.m3u8')) {
      return true
    }

    if (
      typeof src.type === 'string' &&
      ['application/x-mpegurl', 'application/vnd.apple.mpegurl'].includes(
        src.type.toLowerCase()
      )
    ) {
      return true
    }
  }

  return false
}
