import videojs from 'video.js'
import hi from 'video.js/dist/lang/hi.json'

videojs.addLanguage('hi', hi)
videojs.addLanguage('hi', {
  'Error': 'विषय',
  'Error Code': 'विषय कोड',

  // custom hindi translations
  'subtitles off': 'अवलोकन बंद',
  'captions off': 'अवलोकन बंद',
  'audio off': 'ऑडियो बंद',
  'Default': 'डिफ़ॉल्ट',
  'Unknown': 'डिफ़ॉल्ट',
})
