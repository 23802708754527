import { useEffect, useRef, useState } from 'react'
import type { TPlayer } from 'video.js'
import type { VideojsBoxRef } from '../VideojsBox'

/**
 * Hook to hold the VideoJS player instance reference
 */
export function usePlayer() {
  const [player, setPlayer] = useState<TPlayer | null>(null)

  // refs to video tag and player instance, exposed by VideoJS component
  const ref = useRef<VideojsBoxRef>(null)

  // reset player reference when it's disposed
  useEffect(() => {
    player?.on('dispose', () => setPlayer(null))
  }, [player])

  return { player, ref, setPlayer }
}
