import videojs from 'video.js'

export type AudioTrack = videojs.AudioTrack & {
  kind?: string
  id: string
  label?: string
  language?: string
}

export interface AudioTrackList extends EventTarget {
  readonly length: number
  [index: number]: AudioTrack
}

export enum TEXT_TRACK_KIND {
  Subtitles = 'subtitles',
  Captions = 'captions',
  Chapters = 'chapters',
  Descriptions = 'descriptions',
  Metadata = 'metadata',
}

export enum TEXT_TRACK_LABEL {
  SegmentMetadata = 'segment-metadata',
  AdCues = 'ad-cues',
}

export enum TRACK_MODE {
  Disabled = 'disabled',
  Showing = 'showing',
  Hidden = 'hidden',
}

export interface PlayerTextTrack {
  id: string
  label: string
  language: string
  kind: string
  mode: TRACK_MODE
}
