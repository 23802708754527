import { register } from '|>/shared/vjs'
import { BaseMouseTimeDisplay } from '../base'
import { TimeTooltip } from './time-tooltip'

import { bind_, throttle, UPDATE_REFRESH_INTERVAL } from '|>/shared/fn'
import './mouse-time-display.css'

/**
 * Track mouse movement over the progress bar
 */
@register
export class MouseTimeDisplay extends BaseMouseTimeDisplay {
  declare tooltip: TimeTooltip

  constructor(player, options) {
    super(player, options)

    this.updateTooltipForced = bind_(this, this.updateTooltip)
    this.updateTooltip = throttle(
      this.updateTooltipForced,
      UPDATE_REFRESH_INTERVAL
    )
  }

  updateTooltipForced: typeof this.updateTooltip
  updateTooltip(
    rect: { left: number; top: number; width: number; height: number },
    point: number,
    pointPx: number,
    start?: number,
    end?: number
  ) {
    if (start !== undefined && end !== undefined) {
      const time = point * (end - start) + start

      this.tooltip.updateTime(rect, pointPx, time, () => {
        this.el_.style.left = `${pointPx}px`
      })
    } else {
      // call the base class update method if `start` and `end` are not provided
      super.update(rect, point)
    }
  }
}

MouseTimeDisplay.options = {
  children: [
    // display a time above the progress bar
    TimeTooltip.as('tooltip'),
  ],
}
