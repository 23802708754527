import { on, register } from '|>/shared/vjs'
import { BaseButton } from '../base'

import './tap-to-unmute.css'

/**
 * TapToUnmute button component
 */
@register
export class TapToUnmute extends BaseButton {
  override handleClick() {
    this.player_.volume(1)
    this.player_.muted(false)
    this.hide()
  }

  /**
   * Check mute state to show/hide button
   */
  @on(['loadstart', 'loadeddata', 'canplay', 'canplaythrough'])
  check() {
    if (this.player_.muted()) {
      this.show()
    } else {
      this.hide()
    }
  }
}

TapToUnmute.options = {
  className: 'vjs-x-tap-to-unmute vjs-visible-text vjs-hidden',
  controlText: 'Tap to unmute',
}
