import type { TPlayer } from 'video.js'
import { bind_ } from '|>/shared/fn'
import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'
import { SettingsAudioMenu } from './audio/settings-audio-menu'
import { LocalEvents } from './local-events'
import { SettingsMainMenu } from './main/settings-main-menu'
import type { Settings } from './settings'
import { SettingsSubtitlesMenu } from './subtitles/settings-subtitles-menu'

/**
 * Settings menu with audio and subtitles options
 */
@register
export class SettingsMenuContainer extends BaseComponent {
  declare settings: Settings

  // menus
  declare mainMenu: SettingsMainMenu
  declare audioMenu: SettingsAudioMenu
  declare subtitlesMenu: SettingsSubtitlesMenu

  constructor(player: TPlayer, options: any) {
    super(player, options)
    this.hide()

    // set link to this container for child menus
    this.mainMenu.container = this
    this.audioMenu.container = this
    this.subtitlesMenu.container = this

    // listen for local events
    const activateMainMenu = bind_(this, this.activateMainMenu)
    const activateAudioMenu = bind_(this, this.activateAudioMenu)
    const activateSubtitlesMenu = bind_(this, this.activateSubtitlesMenu)
    const closeMenu = bind_(this, this.closeMenu)

    this.on(LocalEvents.SettingsActivateMainMenu, activateMainMenu)
    this.on(LocalEvents.SettingsActivateAudioMenu, activateAudioMenu)
    this.on(LocalEvents.SettingsActivateSubtitlesMenu, activateSubtitlesMenu)
    this.on(LocalEvents.SettingsCloseMenu, closeMenu)
  }

  update() {
    this.mainMenu.update()
    this.audioMenu.update()
    this.subtitlesMenu.update()
  }

  override show() {
    super.show()
    this.player_.addClass('vjs-menu-opened')
  }

  override hide() {
    super.hide()
    this.player_.removeClass('vjs-menu-opened')
  }

  activateMainMenu() {
    this.show()

    // set height to current height for later transition
    const currentHeight = this.el_.offsetHeight
    this.el_.style.height = `${currentHeight}px`

    this.audioMenu.deactivate()
    this.subtitlesMenu.deactivate()
    this.mainMenu.activate()
    this.settings.gearButton.pressed(true)

    // height transition
    requestAnimationFrame(() => {
      this.el_.style.height = `${this.mainMenu.el_.scrollHeight}px`
    })
  }

  activateAudioMenu() {
    this.show()

    // set height to current height for later transition
    const currentHeight = this.el_.offsetHeight
    this.el_.style.height = `${currentHeight}px`

    this.mainMenu.deactivate()
    this.subtitlesMenu.deactivate()
    this.audioMenu.activate()
    this.settings.gearButton.pressed(true)

    // height transition
    requestAnimationFrame(() => {
      this.el_.style.height = `${this.audioMenu.menu?.el_.scrollHeight}px`
    })
  }

  activateSubtitlesMenu() {
    this.show()

    // set height to current height for later transition
    const currentHeight = this.el_.offsetHeight
    this.el_.style.height = `${currentHeight}px`

    this.mainMenu.deactivate()
    this.audioMenu.deactivate()
    this.subtitlesMenu.activate()
    this.settings.gearButton.pressed(true)

    // height transition
    requestAnimationFrame(() => {
      this.el_.style.height = `${this.subtitlesMenu.menu?.el_.scrollHeight}px`
    })
  }

  closeMenu() {
    this.mainMenu.deactivate()
    this.audioMenu.deactivate()
    this.subtitlesMenu.deactivate()
    this.settings.gearButton.pressed(false)
    this.hide()

    // height transition reset
    this.el_.style.height = '0'
  }
}

SettingsMenuContainer.options = {
  className: 'vjs-x-settings-menu-container',
  children: [
    // settings main menu
    SettingsMainMenu.as('mainMenu'),

    // settings subtitles menu
    SettingsSubtitlesMenu.as('subtitlesMenu', { title: 'Subtitles' }),

    // settings audio menu
    SettingsAudioMenu.as('audioMenu', { title: 'Audio' }),
  ],
}
