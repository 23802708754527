import { Events } from '|>/shared/events'
import { div, li, span, textContent } from '|>/shared/h'
import { on, register } from '|>/shared/vjs'
import { BaseMenuItem } from '../../base'
import { LocalEvents } from '../local-events'
import { normalizeTracksTitles } from '../normalize-tracks-titles'
import type { SettingsMenuContainer } from '../settings-menu-container'

/**
 * Subtitles menu item in settings main menu
 */
@register
export class SettingsMainMenuSubtitlesItem extends BaseMenuItem {
  declare container: SettingsMenuContainer | undefined

  declare activeTrackEl_: HTMLSpanElement
  declare labelEl_: HTMLSpanElement

  override createEl() {
    return li('.vjs-menu-item.vjs-x-menu-subtitles-item', [
      span('.vjs-icon-placeholder', { ariaHidden: 'true' }),
      (this.labelEl_ = span(
        '.vjs-menu-item-text',
        this.localize(this.options_.label)
      )),
      (this.activeTrackEl_ = div('.vjs-x-active-track')),
    ])
  }

  @on(Events.Media.SubtitleTrackChanged)
  handleSelectedTrack() {
    let label: string | null | undefined
    let selected = false
    const normalize = normalizeTracksTitles()
    const tracks = this.player_.textTracks()
    for (let i = 0; i < tracks.length; i++) {
      if (['captions', 'subtitles'].includes(tracks[i].kind)) {
        const track = tracks[i]
        label = normalize(track.label || track.language)

        // madeup label for unknown tracks
        if (!label) {
          label = this.localize('Unknown') + ' '
          if (track.kind === 'captions') {
            label += this.localize('Captions')
          } else {
            label += this.localize('Subtitles')
          }
        }

        if (track.mode === 'showing') {
          selected = true
          break
        }
      }
    }
    textContent(
      this.activeTrackEl_,
      selected ? label || '' : this.localize('captions off')
    )

    // force update title to respect locale
    textContent(this.labelEl_, this.localize(this.options_.label))
  }

  update() {
    this.handleSelectedTrack()
  }

  override handleLanguagechange() {
    this.update()
  }

  override handleClick() {
    this.container?.trigger(LocalEvents.SettingsActivateSubtitlesMenu)
  }
}

SettingsMainMenuSubtitlesItem.options = {
  label: 'Subtitles',
}
