import type { TOptions, TPlayer } from 'video.js'
import { Events } from '|>/shared/events'
import './plugin'
import type { CmcdPluginOptions } from './plugin'

// re-export plugin options and plugin class (as a type)
export type { CmcdPlugin, CmcdPluginOptions } from './plugin'

/**
 * CMCD (Common Media Client Data) configuration
 */
export type CmcdConfiguration = CmcdPluginOptions & {
  enabled?: boolean
}

/**
 * Key to store CMCD configuration in player options
 */
const cmcdKey = '~~cmcd'

/**
 * Configure CMCD plugin before player initialization
 */
export function configure(configuration?: CmcdConfiguration) {
  return (options: TOptions): TOptions => {
    // store configuration in player options
    options[cmcdKey] = configuration || {}

    return options
  }
}

/**
 * Injects CMCD plugin and functionality into the player
 */
export function inject(player: TPlayer): TPlayer {
  // update configuration on player configuration update
  player.on(
    Events.Player.UpdateConfiguration,
    (_, configuration?: { cmcd?: CmcdConfiguration }) => {
      const previousCfg = player.options_[cmcdKey] || {}
      const cfg = configuration?.cmcd || {}

      // if CMCD was enabled before
      if (previousCfg.enabled) {
        // update cid/sid
        if (cfg.enabled) {
          player.cmcd().setId(cfg)
        }

        // disable it
        else {
          player.cmcd().disable()
          player.cmcd().dispose()
        }
      }

      // if CMCD was disabled before
      else {
        // enable it
        if (cfg.enabled) {
          player.cmcd(cfg)
          player.cmcd().enable()
        }
      }

      // update configuration in player options
      player.options_[cmcdKey] = cfg
    }
  )

  // enable CMCD right away if it's enabled in player configuration
  const cfg = player.options_[cmcdKey] || {}
  if (cfg.enabled) {
    player.cmcd(cfg)
  }

  return player
}
