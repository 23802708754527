import { useImperativeHandle, useRef } from 'react'
import type { TPlayer } from 'video.js'
import type { VideojsBoxRef } from '../VideojsBox'

/**
 * Player imperative handle
 */
export type PlayerRef = {
  on: (event: string, handler: EventHandler) => void
  off: (event: string) => void
  player: () => TPlayer | null
  wrapper: () => HTMLDivElement | null
  container: () => HTMLDivElement | null
  // slot: () => Element | null | undefined
}

/**
 * Player event handler
 */
export type EventHandler = (payload: any, event?: any) => void

/**
 * Event handler reference
 * - current: current handler, can be changed between renders
 * - stable: stable handler, actually used in subscription
 */
type EventHandlerRef = {
  current: EventHandler
  stable?: Function
}

/**
 * Stable handler
 */
function stableHandler(this: EventHandlerRef, event: any, arg?: any) {
  return this.current(arg, event)
}

/**
 * Hook to export imperative handle to the parent component
 */
export function usePlayerImperativeHandle(
  ref: React.ForwardedRef<PlayerRef>,
  box: VideojsBoxRef | null,
  containerRef: React.RefObject<HTMLDivElement>
): void {
  // hold the references to the event handlers
  // unlike "usual" event handlers, these are not stable, and can be changed between renders
  // pros and cons of this approach:
  // + handlers can be changed between renders, there is no need to wrap them into `useCallback` or use other memoization techniques
  // - there is impossible to add two and more handlers for the same event
  //   ~ as a workaround for that limitation I've added possibility to add multiple handlers
  //     for the same event using semicolons, like `eventName:1`, `eventName:2`, etc.
  const events = useRef<Map<string, EventHandlerRef>>(new Map())

  useImperativeHandle(ref, () => {
    const player = box?.player()
    const wrapper = box?.wrapper()

    // this function called only when `box` is changed,
    // this happens only when player was undefined, and now it's defined
    // so, if player is defined here -> this means that previous player was undefined
    // and now it's defined, so we need to subscribe to all added events
    if (player) {
      for (const [event, ref] of events.current.entries()) {
        const eventName = event.split(':', 1)[0]
        const handler = (ref.stable = stableHandler.bind(ref))
        player.on(eventName, handler)

        // immediately manually call ready handler
        // because when player is defined here, event already happened,
        // and otherwise all 'ready' listeners will not be called
        if (eventName === 'ready') {
          handler(null)
        }
      }
    }

    // return imperative handle
    return {
      /**
       * Add event handler
       * Handler can be changed between renders, this is fine
       * Multiple handlers for the same event can be added using semicolons, like `eventName:1`, `eventName:2`, etc.
       */
      on: (event: string, handler: (payload: any, event?: any) => void) => {
        let ref = events.current.get(event)

        // if ref exists, just update current handler
        if (ref) {
          ref.current = handler
        }

        // otherwise create ref
        else {
          ref = { current: handler }
          events.current.set(event, ref)

          // if player already exists, subscribe to the event
          if (player) {
            const eventName = event.split(':', 1)[0]
            const handler = (ref.stable = stableHandler.bind(ref))
            player.on(eventName, handler)

            // immediately manually call ready handler
            // because when player is defined here, event already happened,
            // and otherwise all 'ready' listeners will not be called
            if (eventName === 'ready') {
              handler(null)
            }
          }
        }
      },

      /**
       * Remove event handler
       */
      off: (event: string) => {
        let ref = events.current.get(event)

        if (ref) {
          events.current.delete(event)

          // if player and stable handler already exists, unsubscribe from the event
          if (player && ref.stable) {
            const eventName = event.split(':', 1)[0]
            player.off(eventName, ref.stable)
          }
        }
      },

      player: () => player || null,
      wrapper: () => wrapper || null,
      container: () => containerRef.current || null,
      // slot: () => playerRef.current instanceof Promise ? null : playerRef.current?.slot.el(),
    }
  }, [box, containerRef])
}
