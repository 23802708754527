import { Events } from '|>/shared/events'
import { addClass, img, removeAttribute, removeClass } from '|>/shared/h'
import { on, register } from '|>/shared/vjs'
import { BaseComponent } from '../base'

import './poster.css'

@register
export class Poster extends BaseComponent {
  declare fallback: string | undefined
  declare imgEl?: HTMLImageElement

  setPoster(url: string): void {
    removeClass(this.el_, 'vjs-x-poster-hidden')

    if (!this.imgEl) {
      this.imgEl = img('.vjs-x-poster-img')
      this.el_.appendChild(this.imgEl)
    }

    if (this.imgEl) this.imgEl.src = url
  }

  loadImage(poster: string) {
    const img = new Image()
    img.src = poster

    img.onload = () => this.setPoster(img.src)
    img.onerror = () => {
      if (this.fallback) this.setPoster(this.fallback)
    }
  }

  @on(Events.Controls.SetPoster)
  onNewPoster(newPoster: Events.Controls.SetPoster) {
    let poster: string | undefined

    if (typeof newPoster !== 'string') {
      poster = newPoster?.poster
      this.fallback = newPoster?.fallback
    } else poster = newPoster

    if (poster) {
      this.loadImage(poster)
    } else {
      addClass(this.el_, 'vjs-x-poster-hidden')
      removeAttribute(this.el_, 'style')
    }
  }
}

Poster.options = {
  className: 'vjs-x-poster',
}
