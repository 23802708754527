import videojs, { type TOptions, type TPlayer } from 'video.js'

const VOLUME_KEY = 'pureya__volume'
const LAST_VOLUME_KEY = 'pureya__last_volume'
const MUTED_KEY = 'pureya__muted'

// read old player data
// TODO: remove this in the next version after deployment
// TODO: https://setplexapps.atlassian.net/browse/FP-3006
const OLD_PLAYER_KEY = 'volumeLevel'

// read old player volume
// TODO: remove this in the next version after deployment
// TODO: https://setplexapps.atlassian.net/browse/FP-3006
function readOldVolume(): string | null {
  try {
    const data = JSON.parse(localStorage.getItem(OLD_PLAYER_KEY) || '{}')
    if (!data) return null
    const volume = data.volumeLevel
    if (typeof volume === 'number' && !isNaN(volume)) {
      if (typeof data.isMuted === 'boolean') {
        localStorage.setItem(
          OLD_PLAYER_KEY,
          JSON.stringify({ isMuted: data.isMuted })
        )
      } else {
        localStorage.removeItem(OLD_PLAYER_KEY)
      }
      return String(volume)
    }
  } catch (_ignored) {}
  return null
}

// read old player muted
// TODO: remove this in the next version after deployment
// TODO: https://setplexapps.atlassian.net/browse/FP-3006
function readOldMuted(): '1' | '0' | null {
  try {
    const data = JSON.parse(localStorage.getItem(OLD_PLAYER_KEY) || '{}')
    if (!data) return null
    const muted = data.isMuted
    if (typeof muted === 'boolean') {
      if (typeof data.volumeLevel === 'number' && !isNaN(data.volumeLevel)) {
        localStorage.setItem(
          OLD_PLAYER_KEY,
          JSON.stringify({ volumeLevel: data.volumeLevel })
        )
      } else {
        localStorage.removeItem(OLD_PLAYER_KEY)
      }
      return muted ? '1' : '0'
    }
  } catch (_ignored) {}
  return null
}

function readVolume(): number | undefined {
  try {
    let data = localStorage.getItem(VOLUME_KEY)

    // read old player volume
    // TODO: remove this in the next version after deployment
    // TODO: https://setplexapps.atlassian.net/browse/FP-3006
    if (!data) {
      data = readOldVolume()
    } else {
      readOldVolume()
    }

    if (!data) return undefined
    const volume = Number(data)
    return isNaN(volume) ? undefined : volume
  } catch (_ignored) {}
}

function readLastVolume(): number | undefined {
  try {
    let data = localStorage.getItem(LAST_VOLUME_KEY)
    if (!data) return undefined
    const volume = Number(data)
    return isNaN(volume) ? undefined : volume
  } catch (_ignored) {}
}

function readMuted(): boolean | undefined {
  try {
    let data = localStorage.getItem(MUTED_KEY)

    // read old player muted
    // TODO: remove this in the next version after deployment
    if (!data) {
      data = readOldMuted()
    } else {
      readOldMuted()
    }

    return data !== '1' && data !== '0' ? undefined : data === '1'
  } catch (_ignored) {}
}

function writeVolume(value: number): number {
  try {
    localStorage.setItem(VOLUME_KEY, String(value))
  } catch (_ignored) {}
  return value
}

function writeLastVolume(value?: number): void {
  try {
    if (value == null) {
      localStorage.removeItem(LAST_VOLUME_KEY)
    } else {
      localStorage.setItem(LAST_VOLUME_KEY, String(value))
    }
  } catch (_ignored) {}
}

function writeMuted(value: boolean): boolean {
  try {
    localStorage.setItem(MUTED_KEY, value ? '1' : '0')
  } catch (_ignored) {}
  return value
}

// use middleware to persist volume level and muted state
videojs.use('*', function (player: any) {
  return {
    /**
     * Get volume level
     */
    volume: function (value: number) {
      const volume = readVolume()
      if (volume != null && value != volume) {
        value = volume
        player.volume(value)
      }
      return value
    },

    /**
     * Set volume level
     */
    setVolume: function (value: number) {
      return writeVolume(value)
    },

    /**
     * Get muted state
     */
    muted: function (value: boolean) {
      const muted = readMuted()
      if (muted != null && value != muted) {
        value = muted
        player.muted(value)
      }
      return value
    },

    /**
     * Set muted state
     */
    setMuted: function (value: boolean) {
      return writeMuted(value)
    },
  }
})

/**
 * Configure persist volume functionality addon before player initialization
 */
export function configure(options: TOptions): TOptions {
  return options
}

/**
 * Injects persist volume functionality addon and functionality into the player
 */
export function inject(player: any): TPlayer {
  /**
   * Override resetCache_ and resetVolumeBar_ to save last volume value
   */
  const original_resetCache_ = player.resetCache_
  player.resetVolumeBar_ = function () {}
  player.resetCache_ = function () {
    original_resetCache_.call(player)
    delete this.cache_.volume
    delete this.cache_.lastVolume
    const last = readLastVolume()
    if (last != null) {
      this.cache_.lastVolume = last
    }
  }

  /**
   * Override lastVolume_ to save last volume value
   */
  const original_lastVolume_ = player.lastVolume_
  player.lastVolume_ = function (value?: number) {
    if (value != null) {
      writeLastVolume(value)
      return original_lastVolume_.call(player, value)
    }

    const last = readLastVolume()
    value = original_lastVolume_.call(player, value)
    if (last != null && value != last) {
      value = last
      original_lastVolume_.call(player, value)
    }
    return value
  }

  /**
   * Override volume to get/set actual persisted volume value
   */
  const original_volume = player.volume
  player.volume = function (value?: number) {
    const volume = original_volume.call(player, value)

    // if there is no middleware set yet, it means that no video was played yet,
    // and tech is not initialized yet, so we just get/set the volume from/to storage
    const hasMiddleware = Boolean(player.middleware_?.length)
    if (!hasMiddleware) {
      if (value != null) {
        const volume = writeVolume(value)
        player.trigger('volumechange')
        return volume
      }

      const volume = readVolume()
      if (volume != null) {
        return volume
      }
    }

    return volume
  }

  /**
   * Override muted to get/set actual persisted muted state
   */
  const original_muted = player.muted
  player.muted = function (value?: boolean) {
    const muted = original_muted.call(player, value)

    // if there is no middleware set yet, it means that no video was played yet,
    // and tech is not initialized yet, so we just get/set the muted state from/to storage
    const hasMiddleware = Boolean(player.middleware_?.length)
    if (!hasMiddleware) {
      if (value != null) {
        const muted = writeMuted(value)
        player.trigger('volumechange')
        return muted
      }

      const muted = readMuted()
      if (muted != null) {
        return muted
      }
    }

    return muted
  }

  return player
}
