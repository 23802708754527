import { register } from '|>/shared/vjs'
import { BaseVolumeBar } from '../base'

@register
export class VolumeBar extends BaseVolumeBar {
  constructor(player, options) {
    super(player, options)
    this.requestAnimationFrame(() => this.update())
  }
}

VolumeBar.options = {
  className: 'vjs-x-volume-bar',
}
