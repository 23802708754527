import type { Src, TPlayer } from 'video.js'
import { Events } from '|>/shared/events'
import { bind_ } from '|>/shared/fn'
import { textContent } from '|>/shared/h'
import { BaseSubsCapsButton } from '../../base'
import { LocalEvents } from '../local-events'
import { normalizeTracksTitles } from '../normalize-tracks-titles'

// In VideoJS sources `TextTrackButton` class (parent of `SubsCapsButton`) incorrectly
// marked as `@extends MenuButton`, while it extends `TrackButton` class in reality.
// This causes TypeScript to go crazy and show type errors,
// so I made this workaround to make it shut up,
// until VideoJS is fixed, or I find a better solution...
interface ISubsCapsButton extends Src.TrackButton {}
const SubsCapsButton: ISubsCapsButton = BaseSubsCapsButton as any

/**
 * Subtitles track button for settings subtitles menu
 *
 * This component is actually (in VideoJS) used as a control bar control,
 * which contains a button and a menu with subtitles tracks.
 * There is no separate menu-only component to select subtitles tracks.
 * I thought it would be easier to use this component as a base, because
 * it already has a menu and subtitles track selection logic, but we don't need
 * a button, so, parent component `SettingsSubtitlesMenu` will just use menu
 * part from this component.
 *
 * I also added logic to trigger event on subtitles track change, I think it's
 * good place for this logic, because this component is already listening
 * to subtitles track changes.
 *
 * No need to be registered as a component in VideoJS,
 * because it is used directly in `SettingsSubtitlesMenu` component.
 */
export class SubtitlesTrackButton extends SubsCapsButton {
  declare menu: NonNullable<InstanceType<typeof SubsCapsButton>['menu']>

  constructor(player: TPlayer, options: any) {
    super(player, options)

    // disable button, this is needed so menu doesn't manage button state
    // and doesn't automatically show/hide itself after menu item is selected
    this.disable()

    // listen to audio track changes
    const tracks = options.tracks
    const updateSelected = bind_(this, this.updateSelected)

    this.handleMenuTitleClick = bind_(this, this.handleMenuTitleClick)
    this.handleMenuAnyTrackClick = bind_(this, this.handleMenuAnyTrackClick)

    player.on(['loadstart', 'texttrackchange'], updateSelected)
    tracks.addEventListener('change', updateSelected)
    tracks.addEventListener('selectedlanguagechange', updateSelected)
    this.on('dispose', () => {
      player.off(['loadstart', 'texttrackchange'], updateSelected)
      tracks.removeEventListener('change', updateSelected)
      tracks.removeEventListener('selectedlanguagechange', updateSelected)
    })
  }

  override update() {
    this.player_.trigger(Events.Media.SubtitleTracksChanged)
    super.update()

    // adjust menu class
    this.menu.addClass('vjs-x-settings-subtitles-menu')

    // normalize track titles
    this.normalizeTrackTitles()

    // adjust menu tap/click behavior
    const that = this
    this.menu.handleTapClick = function (event) {
      const items = this.children() // `this` is Menu instance
      const item = items?.filter((it) => it.el() === event.target)?.[0]
      if (item) {
        if (item.hasClass('vjs-menu-title')) {
          that.handleMenuTitleClick()
        } else {
          that.handleMenuAnyTrackClick()
        }
      }
    }
  }

  updateSelected() {
    this.player_.trigger(Events.Media.SubtitleTrackChanged)
  }

  title(title: string) {
    if (this.menu) {
      const el_ = this.menu.$('.vjs-menu-title') as HTMLElement
      if (el_) {
        textContent(el_, title)
      }
    }
  }

  normalizeTrackTitles() {
    if (this.menu) {
      const normalize = normalizeTracksTitles()
      const items = this.menu.$$('.vjs-menu-item-text')
      for (const item of items) {
        const title = normalize(item.textContent)
        if (title != null) {
          textContent(item as HTMLElement, title)
        }
      }
    }
  }

  handleMenuTitleClick() {
    const container = this.options_.getContainer()
    container?.trigger(LocalEvents.SettingsActivateMainMenu)
  }

  handleMenuAnyTrackClick() {
    const container = this.options_.getContainer()
    container?.trigger(LocalEvents.SettingsCloseMenu)
  }
}
