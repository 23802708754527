import videojs from 'video.js'
import { DEFAULT_SEEK_STEP_SEC } from '|>/shared/constants/rewind'
import { Events } from '|>/shared/events'
import { div } from '|>/shared/h'
import { on, register } from '|>/shared/vjs'
import { BaseComponent } from '../base'

import './tap-handler.css'

// delay between single and double tap
const DOUBLE_TAP_DELAY = 300

// direction of seek
const enum Direction {
  Left = -1,
  Right = 1,
}

/**
 * TapHandler component
 */
@register
export class TapHandler extends BaseComponent {
  // current amount of sec to seek
  sec: number | undefined

  // current direction
  direction: Direction | undefined

  actStateBefore: undefined | boolean

  @on(Events.Media.SeekStep, (step?: number) => step || DEFAULT_SEEK_STEP_SEC)
  seekStep: number = DEFAULT_SEEK_STEP_SEC

  declare doubleTapInActionId?: ReturnType<typeof setTimeout>

  // timeout ids for labels and single tap
  private labelsTimeoutId: number | undefined
  private tapTimeoutId: number | undefined

  constructor(player, options) {
    super(player, options)

    /* Handlers from this component is used by reference by rewind buttons and hotkeys */
    ;(player as any).tapHandler = this

    player.el_.addEventListener('touchend', this.handleTouchEnd.bind(this))
    player.el_.addEventListener('touchstart', this.handleTouchStart.bind(this))
  }

  animateSeek(direction: number) {
    /*
     * Plays animation seek right/left
     */

    /* doubleTapInActionId existence means that animation is on the screen */
    clearTimeout(this.doubleTapInActionId)
    this.doubleTapInActionId = setTimeout(() => {
      this.doubleTapInActionId = undefined
      this.player_.removeClass('x-animate-seek-left')
      this.player_.removeClass('x-animate-seek-right')
    }, 600)

    this.player_.removeClass('x-animate-seek-left')
    this.player_.removeClass('x-animate-seek-right')

    if (direction > 0)
      requestAnimationFrame(() => this.player_.addClass('x-animate-seek-right'))
    else if (direction < 0)
      requestAnimationFrame(() => this.player_.addClass('x-animate-seek-left'))
  }

  handleTouchStart() {
    /* For hiding / setting user to inactive state when tapped
     * goal of this handlers - get user state BEFORE videoJs sets it true.
     * coz if you get value after vjs sets it to true, interface immediately closes
     */
    this.actStateBefore = this.player_.userActive()
  }

  override createEl() {
    const el = div('.vjs-tap')

    // render left and right indicators only on touch devices
    if (videojs.browser.TOUCH_ENABLED) {
      el.appendChild(div('.vjs-left-tap-area', null))
      el.appendChild(div('.vjs-right-tap-area', null))
    }

    return el
  }

  isTargetable(target: HTMLElement) {
    return (
      target &&
      (target.tagName === 'VIDEO' || // tap directly on video
        target.matches('.vjs-tap') || // tap directly on tap handler
        target.closest('.vjs-tap') != null) // tap on child of tap handler
    )
  }

  handleTouchEnd(event) {
    /* player tap handler */

    if (this.tapTimeoutId) {
      // double tap case
      // do not track double taps, on not expected elements
      if (!this.isTargetable(event.target)) return
      /* TODO maybe better track all double tap and filter out vjs-control tap? */

      this.tapTimeoutId = void window.clearTimeout(this.tapTimeoutId)
      this.labelsTimeoutId = void window.clearTimeout(this.labelsTimeoutId)

      const touchX = event.changedTouches[0].clientX
      const playerEl = this.player().el() as HTMLElement
      const playerWidth = playerEl.offsetWidth

      this.handleDoubleTap(
        touchX < playerWidth / 2 // to the left or right of the player
          ? Direction.Left
          : Direction.Right
      )
    } else {
      // single case
      this.tapTimeoutId = window.setTimeout(() => {
        this.handleSingleTap(event)
        this.tapTimeoutId = undefined
      }, DOUBLE_TAP_DELAY)
    }
  }

  handleSingleTap(event) {
    // Ignore clicks on control buttons
    if (event.target.closest('.vjs-control')) return

    // Ignore double-tap last click, that may be odd, and be part of double-tap
    if (this.doubleTapInActionId) return

    if (this.actStateBefore) this.player_.userActive(false)
  }

  handleDoubleTap(direction: Direction) {
    this.player_.seekBar.trySeek({
      shiftSec: direction * this.seekStep,
      isAnimate: true,
    })
  }

  override dispose() {
    window.clearTimeout(this.tapTimeoutId)
    window.clearTimeout(this.labelsTimeoutId)
    super.dispose()
  }
}

export default TapHandler
