import { register } from '|>/shared/vjs'
import { BaseMuteToggle } from '../base'

@register
export class MuteToggle extends BaseMuteToggle {
  handleClickOriginal: undefined | ((event?: Event | undefined) => void)

  constructor(player, options) {
    super(player, options)
    this.requestAnimationFrame(() => this.update())

    // handleHotkeys() called by videoJs with BaseMuteToggle.prototype - so override doesnt work
    this.handleClickOriginal = BaseMuteToggle.prototype.handleClick
    BaseMuteToggle.prototype.handleClick = this.handleClick.bind(this)
  }

  override handleClick(event) {
    this.player_.userActive(true)

    this.handleClickOriginal?.(event)
  }
}

MuteToggle.options = {
  className: 'vjs-x-mute-control',
}
