import { Events } from '|>/shared/events'
import { on, register } from '|>/shared/vjs'
import { BasePlayToggle } from '../base'

import './play-button.css'

/**
 * Central play toggle button
 * https://github.com/videojs/video.js/blob/e78bcc7b2d829fce01451cf105b918d8feec4548/src/js/control-bar/play-toggle.js
 */
@register
export class PlayButton extends BasePlayToggle {
  constructor(player, options) {
    super(player, options)

    // handleHotkeys() inside videoJs call it with BasePlayToggle.prototype - so override doesnt work
    BasePlayToggle.prototype.handleClick = this.handleClick.bind(this)
  }

  // reset button state when switching to a new channel
  @on(Events.Media.Load)
  resetBtnState() {
    this.handlePause()
  }

  override handleClick(event) {
    event.stopPropagation()
    this.player_.togglePause(event)
  }
}

PlayButton.options = {
  className: 'vjs-x-play-button',
}
