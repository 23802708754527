import { type TPlayer } from 'video.js'
import { Events } from '|>/shared/events'
import { div, insertContent, span } from '|>/shared/h'
import { type IMediaError } from '|>/shared/media-error'
import { on, register } from '|>/shared/vjs'
import { BaseModalDialog } from '../base'

import './error-display.css'

@register
export class ErrorDisplay extends BaseModalDialog {
  title = 'Error'
  codeTitle = 'Error Code'

  // saved error object
  error_?: IMediaError

  // keep track of the title of the content that is being played
  private contentTitle: string = ''

  // keep track of the source URL
  @on(Events.Media.Load, (src: Events.Media.Load) => src?.src)
  private sourceUrl?: string

  declare titleEl?: HTMLElement
  declare descriptionEl?: HTMLElement
  declare codeTitleEl?: HTMLElement
  declare codeEl?: HTMLElement

  constructor(player: TPlayer, options) {
    super(player, options)
    this.descEl_.remove()

    // manually triggered player error
    // it will be handled by the hook, and then 'error' event will be triggered bellow
    this.player_.on(Events.Media.Error, (_, error: Events.Media.Error) => {
      this.player_.error(error)
    })

    // triggered by player error
    // as well as manually triggered player error above
    this.player_.on('error', () => {
      this.showError(this.player_.error())
    })

    // handle HTTP errors
    this.player_.on('xhr-hooks-ready', () => {
      const responseHook = (request, _error, response) => {
        // ignore requests other than the current playback url (e.g. chunks)
        if (this.sourceUrl !== request.url) return

        // trigger special player error for some HTTP errors
        if (
          [
            400, // BAD_REQUEST
            402, // PAYMENT_REQUIRED
            403, // ACCESS_ERROR
            404, // NOT_FOUND
            451, // UNAVAILABLE_FOR_LEGAL_REASONS
            500, // API_ERROR
          ].includes(response?.statusCode)
        ) {
          this.player_.error({
            code: response.statusCode,
            metadata: {
              requestUrl: request.url,
              statusCode: response.statusCode,
            },
          })
        }
      }

      // hook to intercept internal xhr responses
      this.player_.tech_.vhs.xhr.onResponse(responseHook)
    })

    // TODO: not sure if this works at all, need to investigate more
    // triggered by player tech error
    // this.player_.tech_.on('error', () => {
    //   this.showError(this.player_.tech_.error_)
    // })
  }

  showError(error: IMediaError) {
    this.error_ = error
    this.player_.pause()
    this.player_.reset()
    this.addErrorClass()
    this.open()
  }

  @on(Events.Media.Load)
  hideError() {
    this.error_ = undefined
    this.close()
    this.removeErrorClass()
  }

  addErrorClass() {
    this.player_.addClass('vjs-error')
  }

  removeErrorClass() {
    this.player_.removeClass('vjs-error')
  }

  override content() {
    const elements = [
      (this.titleEl = div('.error-title', this.localize(this.title))),
      (this.descriptionEl = div('.error-description')),
      div(
        '.error-code',
        (this.codeTitleEl = span('.error-code-title')),
        (this.codeEl = span('.error-code-name'))
      ),
    ]
    this.fillError()
    return elements
  }

  /**
   * Fill content elements with localized strings
   */
  fillError() {
    const error = this.error_
    if (!error) return

    let title = 'title' in error ? error.title || this.title : this.title
    let message = error.message || ''
    let code = error.code

    // localize using values
    title = this.localize(title) // "Error" string by default
    message = this.localize(message, [
      this.contentTitle ? `"${this.contentTitle}"` : 'content', // replace positional placeholder "{1}" with current content title
    ])
    let codeTitle = this.localize(this.codeTitle) // "Error Code" string

    // fill elements with localized strings
    if (this.titleEl) insertContent(this.titleEl, `${title}`)
    if (this.descriptionEl) insertContent(this.descriptionEl, `${message}`)
    if (this.codeTitleEl) insertContent(this.codeTitleEl, `${codeTitle}`)
    if (this.codeEl) insertContent(this.codeEl, `${code}`)
  }

  /**
   * Keep track of the title of the content that is being played
   * and re-fill error with updated content title
   */
  @on(Events.Media.TitleChanged)
  updateContentTitle(title: Events.Media.TitleChanged) {
    this.contentTitle = title.title || ''
    this.fillError()
  }

  /**
   * Re-fill error on language change
   */
  override handleLanguagechange() {
    this.fillError()
  }
}

// Default values
ErrorDisplay.options = {
  className: 'vjs-x-error-display',
  pauseOnOpen: true,
  fillAlways: true,
  temporary: false,
  uncloseable: true,
}
