import videojs, { type TPlayer } from 'video.js'
import { Events } from '|>/shared/events'
import { bind_ } from '|>/shared/fn'
import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'
import { asArray, isHotkey } from './lib'

const HOTKEYS_SEQUENCE_MS = 1500

@register
export class Hotkeys extends BaseComponent {
  handlers: [string[], () => void][] = []
  sequence: string[] = []
  resetSequenceTimeoutId: number | undefined

  // in case of many matching sequences delay gives you option to enter longer seq. option
  exactHandlerDelayId: number | undefined

  constructor(player: TPlayer, options: any) {
    super(player, options)

    this.handleModalKeydown = bind_(this, this.handleModalKeydown)
    this.handleHotKeysWithCustom = bind_(this, this.handleHotKeysWithCustom)

    // register hotkeys from any other component
    this.player_.on(
      Events.Controls.RegisterHotkey,
      (_: any, payload: Events.Controls.RegisterHotkey) => {
        if (isHotkey(payload)) {
          this.registerHotkey(asArray(payload))
        } else {
          for (const hotkey of payload) {
            this.registerHotkey(asArray(hotkey))
          }
        }
      }
    )

    // handle global hotkeys
    this.player_.on('modalKeydown', this.handleModalKeydown)
    videojs.on(document, 'keydown', this.handleHotKeysWithCustom)
  }

  handleModalKeydown({ originalEvent }: { originalEvent: KeyboardEvent }) {
    this.handleHotKeysWithCustom({
      ...originalEvent,
      key: originalEvent.key + '!',
    })
  }

  handleHotKeysWithCustom(event: KeyboardEvent) {
    if (this.player_ == null) return
    this.player_.handleHotkeys(event)

    // if there is no handlers - do nothing
    if (this.handlers.length === 0) return

    window.clearTimeout(this.exactHandlerDelayId)

    this.sequence.push(event.key.toLowerCase())
    this.checkSequence()

    window.clearTimeout(this.resetSequenceTimeoutId)
    this.resetSequenceTimeoutId = window.setTimeout(() => {
      this.sequence = []
    }, HOTKEYS_SEQUENCE_MS)

    // place for more global hotkeys, only actually global hotkeys here,
    // if hotkey is used in some component - it is better to register it
    // via event `Events.Controls.RegisterHotkey`, than to handle it here
  }

  getHandlerFromMatchByLength(
    matches: [string[], () => void][],
    length: number
  ): (() => void) | undefined {
    return matches.find((match) => match[0].length === length)?.[1]
  }

  checkSequence() {
    let matches: [string[], () => void][] = [] as any // matches [[hotkeys, handler], []...]

    // search for exact match in handlers
    seq: for (const [hotkeys, handler] of this.handlers) {
      if (this.sequence.length > hotkeys.length) continue seq

      for (let i = 0; i < this.sequence.length; i++) {
        if (hotkeys[i] !== this.sequence[i]) continue seq
      }

      matches.push([hotkeys, handler])
    }

    if (matches.length === 0) return

    const exactHandler = this.getHandlerFromMatchByLength(
      matches,
      this.sequence.length
    )

    if (matches.length > 1) {
      window.clearTimeout(this.resetSequenceTimeoutId)
      // if user don't press any next button - call exact match handler from many
      this.exactHandlerDelayId = window.setTimeout(
        () => exactHandler?.(),
        HOTKEYS_SEQUENCE_MS
      )
    }

    if (matches.length === 1) exactHandler?.()
  }

  registerHotkey([key, handler]: Events.Controls.ArrayHotkey) {
    this.handlers.push([Array.isArray(key) ? key : [key], handler])
  }

  override dispose(options?: { originalEl: Element }) {
    window.clearTimeout(this.exactHandlerDelayId)
    window.clearTimeout(this.resetSequenceTimeoutId)
    videojs.off(document, 'keydown', this.handleHotKeysWithCustom)
    this.player_?.off('modalKeydown', this.handleModalKeydown)
    super.dispose(options)
  }
}

Hotkeys.options = {
  createEl: false,
}
