import { DEFAULT_SEEK_STEP_SEC } from '|>/shared/constants/rewind'
import { Events } from '|>/shared/events'
import { on, register } from '|>/shared/vjs'
import { BaseButton } from '../base'

import './rewind-button.css'

/**
 * Rewind / seek button component
 */
@register
export class RewindButton extends BaseButton {
  direction: 1 | -1

  @on(Events.Media.SeekStep, (step?: number) => step || DEFAULT_SEEK_STEP_SEC)
  seekStep: number = DEFAULT_SEEK_STEP_SEC

  constructor(player, options) {
    super(player, options)

    this.direction = options.className?.includes('left') ? -1 : 1
  }

  override handleClick() {
    this.player_.seekBar.trySeek({ shiftSec: this.direction * this.seekStep })
  }
}
