import { Events } from '|>/shared/events'
import { register } from '|>/shared/vjs'
import { BaseButton } from '../base'

import './close-button.css'

/**
 * Top-right close button component
 * https://github.com/videojs/video.js/blob/e78bcc7b2d829fce01451cf105b918d8feec4548/src/js/button.js
 */
@register
export class CloseButton extends BaseButton {
  override handleClick() {
    this.player_.trigger(Events.Player.Close)
  }
}

CloseButton.options = {
  className: 'vjs-x-close-button',
}
