import type { TPlayer } from 'video.js'
import { Events } from '|>/shared/events'
import { register } from '|>/shared/vjs'
import { BaseComponent } from '../../base'
import type { SettingsMenuContainer } from '../settings-menu-container'
import { AudioTrackButton } from './audio-track-button'

/**
 * Settings audio menu with available audio tracks
 */
@register
export class SettingsAudioMenu extends BaseComponent {
  declare container: SettingsMenuContainer | undefined

  trackButton_: AudioTrackButton
  menu: AudioTrackButton['menu'] | undefined

  constructor(player: TPlayer, options: any) {
    super(player, options)

    this.trackButton_ = new AudioTrackButton(player, {
      title: this.localize(options.title),
      getContainer: () => this.container,
    })
  }

  override contentEl() {
    // return _parent_'s container element, so any child, added to this component,
    // will be added to the parent container's element instead
    if (!this.container) throw new Error('container is not set')
    return this.container.contentEl()
  }

  override handleLanguagechange() {
    this.trackButton_.title(this.localize(this.options_.title))
  }

  preselectTrack() {
    const tracks = this.player_.audioTracks()

    // find selected track
    let selected: any = null
    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i]
      if (track.enabled) {
        selected = track
        break
      }
    }

    // if no track is selected, select the first one
    if (selected == null) {
      selected = tracks[0]

      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i]
        track.enabled = track === selected
      }

      this.player_.trigger(Events.Media.AudioTracksChanged)
    }
  }

  update() {
    this.preselectTrack()

    this.trackButton_.update()

    if (this.menu) {
      this.menu.dispose()
      this.removeChild(this.menu)
    }

    this.menu = this.trackButton_.menu
    this.addChild(this.menu)
  }

  activate() {
    this.menu?.addClass('vjs-x-active')
  }

  deactivate() {
    this.menu?.removeClass('vjs-x-active')
  }
}

SettingsAudioMenu.options = {
  createEl: false, // this component is opaque, it has no element
}
