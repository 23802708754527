import { Events } from '|>/shared/events'
import { on, register } from '|>/shared/vjs'
import { BaseLiveTracker } from '../base'

@register
export class LiveTracker extends BaseLiveTracker {
  @on(Events.Media.IsRewound)
  private isRewound: Events.Media.IsRewound = false

  @on(Events.Media.IsAutoLiveTracker)
  private isAutoLiveTracker: Events.Media.IsAutoLiveTracker = false

  override toggleTracking() {
    if (this.isAutoLiveTracker) return super.atLiveEdge()

    if (
      this.player_.duration() === Infinity &&
      this.liveWindow() >= this.options_.trackingThreshold
    ) {
      this.startTracking()
    } else {
      this.stopTracking()
    }
  }

  override atLiveEdge() {
    if (this.isAutoLiveTracker) return super.atLiveEdge()

    return !this.isRewound
  }
}

// Default values
LiveTracker.options = {
  // liveTolerance: 10, // Default: 15s
  trackingThreshold: 0, // Default: 20s // no metter stream dur. - live ui is shown
}
