import videojs, { type TPlayer } from 'video.js'
import { Events } from '|>/shared/events'
import { MediaError } from '|>/shared/media-error'
import type { ExternalError } from './index.h'

// update error debug handlers
// TODO: think about more convenient way to debug player errors
export function updateDebugErrorHandlers(
  map: Map<number | string | symbol, ExternalError>
) {
  const handlers = {}

  // native errors
  for (let i = 0; i < MediaError.errorTypes.length; i++) {
    const c = MediaError.errorTypes[i]
    handlers[c] = handlers[i] = () => void triggerError({ code: i })
  }

  // custom errors
  for (const [c, error] of map) {
    let { code, ...err } = typeof error === 'object' ? error : { code: error }
    code = typeof code === 'symbol' ? code.description : code
    if (code) {
      handlers[code] = () => void triggerError({ code: c, ...err })
    }
  }

  // set debug handlers to window
  ;(window as any).error = handlers
}

function triggerError(error: ExternalError) {
  const players: Record<string, TPlayer | null> = videojs.getPlayers()
  for (const player of Object.values(players)) {
    if (player && !player.isDisposed()) {
      player.trigger(Events.Media.Error, error)
    }
  }
}
