import videojs from 'video.js'
import es from 'video.js/dist/lang/es.json'

videojs.addLanguage('es', es)
videojs.addLanguage('es', {
  'Error': 'Error',
  'Error Code': 'Código de error',

  // custom spanish translations
  'subtitles off': 'Off',
  'captions off': 'Off',
  'audio off': 'Off',
  'Default': 'Predeterminado',
  'Unknown': 'Predeterminado',
})
