import { register } from '|>/shared/vjs'
import { BaseVolumeControl } from '../base'
import { VolumeBar } from './volume-bar'

@register
export class VolumeControl extends BaseVolumeControl {
  declare volumeBar: VolumeBar

  constructor(player, options) {
    // workaround for https://github.com/videojs/video.js/blob/ca6f8235453d0d45ae4d05c8b056e2a8c206bc26/src/js/component.js#L748-L750
    // with the following code:
    // ```js
    // if (parentOptions[name] !== undefined) {
    //   opts = parentOptions[name];
    // }
    // ```
    // videojs effectively overrides the `volumeBar` option and loose
    // our custom component class, so we need to pass it explicitly
    // TODO: make a PR to videojs to fix this
    options = {
      volumeBar: {
        componentClass: VolumeBar.componentClass, // <- set child `volumeBar` component class explicitly
      },
      ...options,
    }

    super(player, options)
  }

  makeActive() {
    // https://github.com/videojs/video.js/blob/964c40e18618d034e7bd3ba65a3514a9efc6df3b/src/js/control-bar/volume-control/volume-control.js#L55
    this.volumeBar.addClass('vjs-slider-active')
    this.addClass('vjs-slider-active')
    this.trigger('slideractive')
  }

  makeInactive() {
    this.volumeBar.removeClass('vjs-slider-active')
    this.removeClass('vjs-slider-active')
    this.trigger('sliderinactive')
  }
}

VolumeControl.options = {
  className: 'vjs-x-volume-control',
  children: [VolumeBar.as('volumeBar')],
}
