import { Events } from '|>/shared/events'
import { on, register } from '|>/shared/vjs'
import { BaseProgressControl, BaseSeekBar } from '../base'

import { findPosition, getPointerPosition } from '|>/shared/h'
import { MouseBar } from './mouse-bar'
import './vod-progress-control.css'

/**
 * VOD progress bar control
 * https://github.com/videojs/video.js/blob/e78bcc7b2d829fce01451cf105b918d8feec4548/src/js/control-bar/progress-control/progress-control.js
 */
@register
export class VODProgressControl extends BaseProgressControl {
  @on('ended')
  checkProgramProgress() {
    this.player_.trigger(Events.Player.ProgressReachedEnd)
  }

  override handleMouseMove(event) {
    super.handleMouseMove(event)

    const seekBar = this.getChild('seekBar')
    const mouseBar = seekBar?.getChild('mouseBar') as MouseBar

    if (!seekBar || !mouseBar) {
      return
    }

    const el = seekBar.el()
    const rect = findPosition(el)
    const point = getPointerPosition(el, event).x

    mouseBar.update(rect, point)

    // update current playing time
    // if (playProgressBar) {
    //   playProgressBar.update(seekBarRect, seekBar.getProgress())
    // }
  }
}

VODProgressControl.options = {
  className: 'vjs-x-vod-progress-control',
  children: [
    BaseSeekBar.as('seekBar', [
      MouseBar.as('mouseBar'),
      'loadProgressBar',
      'playProgressBar',
    ]),
  ],
}
