import { Events } from '|>/shared/events'
import { toAbs } from '|>/shared/utils'
import { on, register } from '|>/shared/vjs'
import { CurrentTimeDisplay } from './current-time-display'

const CORRECTION_FOR_TIMESHIFT = 55 * 1000

@register
export class LiveCurrentTimeDisplay extends CurrentTimeDisplay {
  seekEnd_: number | undefined
  end_: number | undefined
  isInteractive: boolean = true

  get seekEnd() {
    return toAbs(this.seekEnd_)
  }
  get end() {
    return this.end_
  }

  @on(Events.Media.IsRewindPending)
  private isRewindPending: Events.Media.IsRewindPending = false

  @on(Events.Media.IsRewound)
  private isRewound: Events.Media.IsRewound = false

  @on(Events.Media.RewoundTargetSec)
  private rewoundTargetStartSec: Events.Media.RewoundTargetSec = 0

  @on(Events.Media.RewoundUrlSec)
  private rewoundUrlStartSec: Events.Media.RewoundUrlSec = 0

  override updateBoundaries(boundaries: Events.Media.ProgressBarChanged) {
    if (boundaries === false) {
      this.disable()
      return
    }

    // `boundaries` possible values here:
    //   - null
    //   - [start, seekStart, seekEnd, end]
    //   - [start, seekStart, seekEnd, end, isInteractive = true]
    this.seekEnd_ = boundaries?.[2]
    this.end_ = boundaries?.[3]
    this.isInteractive = boundaries ? (boundaries[4] ?? true) : false

    if (boundaries) {
      this.enable()
    } else {
      this.disable()
    }

    this.updateForced() // force update
  }

  getRewoundTimeToProgramEnd() {
    if (this.end === undefined || this.seekEnd === undefined) return

    const notSeekableProgLeftMs = this.end !== 0 ? this.end - this.seekEnd : 0 // epg : no epg

    /* time shift ↓ */
    if (this.rewoundTargetStartSec === this.rewoundUrlStartSec) {
      const currentTimeMs = this.player_.currentTime() * 1000
      const seekableProgLeftMs =
        this.seekEnd - this.rewoundTargetStartSec * 1000 - currentTimeMs

      // TODO same correction value located in tria -> so maybe move those calculations to tria?
      return (
        seekableProgLeftMs + notSeekableProgLeftMs - CORRECTION_FOR_TIMESHIFT
      )
    }

    /* dvr + rewind ↓ */
    let realOffsetMs =
      (this.player_.liveDuration.estimatedEnd - this.player_.currentTime()) *
      1000
    if (realOffsetMs < 0) realOffsetMs = 0

    return notSeekableProgLeftMs + realOffsetMs
  }

  override update() {
    // ignore updates while the tab is hidden
    // TODO: add listener for visibility change -> this.on(document, 'visibilitychange', this.toggleVisibility_)
    if (document.visibilityState === 'hidden') {
      return
    }

    if (
      this.end === undefined ||
      this.seekEnd === undefined ||
      !this.isInteractive
    ) {
      this.setEmptyTime()
      return
    }

    /* Rewound ↓ */
    if (this.isRewound && !this.isRewindPending)
      return this.setTime(this.getRewoundTimeToProgramEnd())

    /* Live ↓ */
    if (this.end === 0) {
      /* no epg case */
      return this.setEmptyTime()
    }

    return this.setTime(this.end - this.seekEnd)
  }
}

LiveCurrentTimeDisplay.options = {
  className: 'vjs-x-current-time-display vjs-x-live-current-time-display',
}
