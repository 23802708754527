import videojs from 'video.js'

export const { UPDATE_REFRESH_INTERVAL, bind_, throttle, debounce } =
  videojs.fn as {
    UPDATE_REFRESH_INTERVAL: number
    bind_<F extends Function>(context: any, fn: F, uid?: number): F
    throttle<F extends Function>(fn: F, wait: number): F
    debounce<F extends Function>(
      func: F,
      wait: number,
      immediate?: boolean,
      context?: any
    ): F
  }

/**
 * (Not so) simple `flow` function implementation, it accepts an initial value and a list of functions to apply to the value
 * It is impossible to make universal types for this function, so it is limited to 6 functions
 */
// prettier-ignore
export const flow: {
  <I, Q>(
    init: I,
    fn1: ((arg: I) => Q) | Promise<(arg: I) => Q>
  ): Q
  <I, Q, W>(
    init: I,
    fn1: ((arg: I) => Q) | Promise<(arg: I) => Q>,
    fn2: ((arg: Q) => W) | Promise<(arg: Q) => W>
  ): W
  <I, Q, W, E>(
    init: I,
    fn1: ((arg: I) => Q) | Promise<(arg: I) => Q>,
    fn2: ((arg: Q) => W) | Promise<(arg: Q) => W>,
    fn3: ((arg: W) => E) | Promise<(arg: W) => E>
  ): E
  <I, Q, W, E, R>(
    init: I,
    fn1: ((arg: I) => Q) | Promise<(arg: I) => Q>,
    fn2: ((arg: Q) => W) | Promise<(arg: Q) => W>,
    fn3: ((arg: W) => E) | Promise<(arg: W) => E>,
    fn4: ((arg: E) => R) | Promise<(arg: E) => R>
  ): R
  <I, Q, W, E, R, T>(
    init: I,
    fn1: ((arg: I) => Q) | Promise<(arg: I) => Q>,
    fn2: ((arg: Q) => W) | Promise<(arg: Q) => W>,
    fn3: ((arg: W) => E) | Promise<(arg: W) => E>,
    fn4: ((arg: E) => R) | Promise<(arg: E) => R>,
    fn5: ((arg: R) => T) | Promise<(arg: R) => T>
  ): T
  <I, Q, W, E, R, T, Y>(
    init: I,
    fn1: ((arg: I) => Q) | Promise<(arg: I) => Q>,
    fn2: ((arg: Q) => W) | Promise<(arg: Q) => W>,
    fn3: ((arg: W) => E) | Promise<(arg: W) => E>,
    fn4: ((arg: E) => R) | Promise<(arg: E) => R>,
    fn5: ((arg: R) => T) | Promise<(arg: R) => T>,
    fn6: ((arg: T) => Y) | Promise<(arg: T) => Y>
  ): Y
  <I>(
    init: I,
    ...fns: Array<((arg: I) => I) | Promise<(arg: I) => I>>
  ): I
} = (init: any, ...fns: Array<Function | Promise<Function>>) =>{
  if (!fns || fns.length === 0) {
    return init
  }

  // check that all arguments are functions or there are promises exists
  let sync = true
  for (const fn of fns) {
    if (fn instanceof Promise) {
      sync = false
      break
    }
  }

  // all functions -> just reduce synchronously
  if (sync) {
    return fns.reduce(
      (value, fn) => (fn as Function)(value),
      init
    )
  }

  // some functions are promises -> reduce asynchronously
  return fns.reduce(
    async (value, fn) => {
      const [value_, fn_] = await Promise.all([value, fn])
      return fn_(value_)
    },
    Promise.resolve(init)
  )
}
