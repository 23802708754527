import * as ezdrm from './ezdrm'
import * as unsupported from './unsupported'

export const widevine = <T>(provider: string | undefined, options: T): T =>
  ((
    {
      EZDRM: ezdrm.widevine,
    }[String(provider)] || unsupported.widevine
  )(options))

export const playready = <T>(provider: string | undefined, options: T): T =>
  ((
    {
      EZDRM: ezdrm.playready,
    }[String(provider)] || unsupported.playready
  )(options))

export const fairplay = <T>(provider: string | undefined, options: T): T =>
  ((
    {
      EZDRM: ezdrm.fairplay as any,
    }[String(provider)] || unsupported.fairplay
  )(options))
