import { Events } from '|>/shared/events'
import { UPDATE_REFRESH_INTERVAL, bind_, throttle } from '|>/shared/fn'
import {
  findPosition,
  getBoundingClientRect,
  getPointerPosition,
} from '|>/shared/h'
import { throttleNext } from '|>/shared/helpers/throttle'
import { toAbs } from '|>/shared/utils'
import { on, register } from '|>/shared/vjs'
import { BaseComponent } from '../base'
import { LiveFullBar } from './live-full-bar'
import { LiveSeekBar } from './live-seek-bar'
import { LiveUnavailableBar } from './live-unavailable-bar'

import './live-progress-control.css'

const ONE_MINUTE_MS = 60000

/**
 * Progress bar control
 */
@register
export class LiveProgressControl extends BaseComponent {
  declare fullBar: LiveFullBar
  declare seekBar: LiveSeekBar

  start_: number | undefined
  seekStart_: number | undefined
  seekEnd_: number | undefined
  end_: number | undefined
  isInteractive: boolean = true

  get start() {
    return toAbs(this.start_)
  }
  get seekStart() {
    return toAbs(this.seekStart_)
  }
  get seekEnd() {
    return toAbs(this.seekEnd_)
  }
  get end() {
    return toAbs(this.end_)
  }

  @on(Events.Media.IsRewound)
  private isRewound: Events.Media.IsRewound = false

  triggerProgressReachedEnd: () => void

  enabled_: boolean = false
  updateInterval: number | undefined

  constructor(player) {
    super(player)

    this.handleMouseMove = throttle(
      bind_(this, this.handleMouseMove),
      UPDATE_REFRESH_INTERVAL
    )

    this.updateForced = bind_(this, this.update)
    this.update = throttle(this.updateForced, UPDATE_REFRESH_INTERVAL)

    this.triggerProgressReachedEnd = throttleNext(
      this.triggerProgressReachedEnd_,
      ONE_MINUTE_MS
    )

    this.hide()
  }

  triggerProgressReachedEnd_() {
    this.player_.trigger(Events.Player.ProgressReachedEnd)
  }

  @on(Events.Media.ProgressBarChanged)
  updateBoundaries(boundaries: Events.Media.ProgressBarChanged) {
    if (boundaries === false) {
      this.hide()
      this.disable()
      return
    }

    // `boundaries` possible values here:
    //   - null
    //   - [start, seekStart, seekEnd, end]
    //   - [start, seekStart, seekEnd, end, isInteractive = true]
    this.start_ = boundaries?.[0]
    this.seekStart_ = boundaries?.[1]
    this.seekEnd_ = boundaries?.[2]
    this.end_ = boundaries?.[3]
    this.isInteractive = boundaries ? (boundaries[4] ?? true) : false

    // adjust seekStart if not interactive
    // because in that case we don't care about possible rewind length, just the current position in epg
    if (!this.isInteractive && this.seekStart !== this.start_) {
      this.seekStart_ = this.start_
    }

    if (boundaries) {
      this.enable()
    } else {
      this.disable()
    }

    this.show()
    this.updateForced() // force update
  }

  isMobile() {
    return this.player_.hasClass('vjs-touch-enabled')
  }

  getSeekBarRectShifted() {
    const seekBarRect = findPosition(this.seekBar.el())
    const fullBarRect = findPosition(this.fullBar.el())

    const rect = {
      ...seekBarRect,
      width: fullBarRect.width,
      left: seekBarRect.left - fullBarRect.left,
      right: fullBarRect.right,
    }

    return rect
  }

  get seekStartMax() {
    return Math.max(this.seekStart!, this.start!)
  }

  get seekEndMin() {
    return Math.min(this.seekEnd!, this.end!)
  }

  updateForced: typeof this.update
  update() {
    // ignore updates while the tab is hidden
    // TODO: add listener for visibility change -> this.on(document, 'visibilitychange', this.toggleVisibility_)
    if (document.visibilityState === 'hidden') {
      return
    }

    this.requestNamedAnimationFrame('LiveProgressControl#update', () => {
      this.setInteractive(this.isInteractive)

      // if boundaries are not set - fill full width of the progress bar
      if (
        this.start_ === undefined ||
        this.end_ === undefined ||
        this.seekStart_ === undefined ||
        this.seekEnd_ === undefined
      ) {
        this.seekBar.updateOwnPositionForced(1, [0, 0, 1, 1])
        return
      }

      if (this.isMobile()) {
        const seekBarRect = findPosition(this.seekBar.el())
        const point = this.seekBar.getProgress()
        const pointPx = point * seekBarRect.width

        const seekBarRectShifted = this.getSeekBarRectShifted()

        this.seekBar.livePlayProgressBar.mouseTimeDisplay.updateTooltip(
          seekBarRectShifted,
          point,
          pointPx,
          this.seekStartMax!,
          this.seekEndMin!
        )
      }

      const rect = getBoundingClientRect(this.fullBar.el())
      this.seekBar.updateOwnPosition(rect.width, [
        this.start!,
        this.seekStartMax!,
        this.seekEndMin!,
        this.end!,
      ])
    })
  }

  @on('timeupdate')
  checkProgramProgress() {
    /*
     * FR-7
     */
    if (typeof this.seekEnd !== 'number' && typeof this.end !== 'number') return

    const isFullySeekable = this.seekEnd! >= this.end!

    if (this.isRewound) {
      // rewound playback program ended moment track case

      // getRPercent() from seekBar is easier, but not used coz returns tempPercent
      const isPlayableEndReached =
        (this.seekBar?.getRewoundPercent?.() ?? 0) >= 1

      if (isFullySeekable && isPlayableEndReached)
        this.triggerProgressReachedEnd()
    }

    if (!this.isRewound) {
      // live playback program ended moment track case
      const isPlayableEndReached = this.seekBar?.getLivePercent?.() >= 1

      if (isFullySeekable && isPlayableEndReached)
        this.triggerProgressReachedEnd()
    }
  }

  handleMouseMove(event) {
    const el = this.seekBar.el()
    const rect = findPosition(el)
    const point = getPointerPosition(el, event).x
    const pointPx = point * rect.width

    const seekBarRectShifted = this.getSeekBarRectShifted()

    if (!this.isMobile()) {
      this.seekBar.livePlayProgressBar.mouseTimeDisplay.updateTooltip(
        seekBarRectShifted,
        point,
        pointPx,
        this.seekStartMax!,
        this.seekEndMin!
      )
    }
    this.seekBar.mouseBar.update(rect, point)

    // update current playing time
    // if (playProgressBar) {
    //   playProgressBar.update(seekBarRect, seekBar.getProgress())
    // }
  }

  setInteractive(interactive: boolean) {
    if (interactive) {
      this.removeClass('non-interactive')
    } else {
      this.addClass('non-interactive')
    }
  }

  enable() {
    this.children().forEach((child) => child.enable && child.enable())

    if (this.enabled_) {
      return
    }

    this.seekBar.on('mousemove', this.handleMouseMove)

    if (!this.updateInterval) {
      this.updateInterval = this.setInterval(
        this.update,
        UPDATE_REFRESH_INTERVAL
      )
    }

    this.removeClass('disabled')

    this.enabled_ = true
  }

  disable() {
    this.children().forEach((child) => child.disable && child.disable())

    if (!this.enabled_) {
      return
    }

    this.seekBar.off('mousemove', this.handleMouseMove)

    if (this.updateInterval) {
      this.clearInterval(this.updateInterval)
      this.updateInterval = undefined
    }

    this.addClass('disabled')

    this.enabled_ = false
  }
}

LiveProgressControl.options = {
  className: 'vjs-progress-control vjs-control vjs-x-live-progress-control',
  children: [
    // live stream full (could be partly disabled) progress bar
    LiveFullBar.as('fullBar'),

    // live stream seekable bar
    LiveSeekBar.as('seekBar'),

    // live stream unavailable bar
    LiveUnavailableBar.as('unavailableBar'),
  ],
}
