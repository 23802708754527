import { useMemo } from 'react'
import type { TOptions } from 'video.js'
import type { Options } from '|>/core'

/**
 * Hook to handle VideoJS options
 * For now just an example
 * Returns memoized options object, that will not change between renders
 */
export function useOptions({ language, muted }: Options) {
  return useMemo(() => {
    return {
      language,
      muted,
    } satisfies TOptions
  }, [language])
}
