// import type { KeySystems } from '../../videojs/index.h'
type KeySystems = any

// use common widevine and playready settings
export { playready, widevine } from './common'

// cache for fps certificates
const fpsCertificateCache = new Map<string, Uint8Array>()

// adjust fairplay license acquisition
export const fairplay = (options: {
  certificateUri: string
  licenseUri: string
}): KeySystems['com.apple.fps'] => ({
  getCertificate(_emeOptions, callback) {
    const fpsCertificateUrl = options.certificateUri

    // get certificate from cache
    if (fpsCertificateCache.has(fpsCertificateUrl)) {
      callback(null, fpsCertificateCache.get(fpsCertificateUrl))
      return
    }

    // otherwise fetch it
    fetch(fpsCertificateUrl, {
      method: 'GET',
      headers: {
        'Content-type': 'application/octet-stream',
      },
    })
      .then((response) => {
        if (response.ok) {
          return response
        } else {
          throw response
        }
      })
      .then((response) => response.arrayBuffer())
      .then((responseBody) => {
        const certificate = new Uint8Array(responseBody)
        fpsCertificateCache.set(fpsCertificateUrl, certificate)
        callback(null, certificate)
      })
      .catch((error) => callback(error))
  },

  getContentId(_emeOptions, initData) {
    const skd =
      typeof initData === 'string'
        ? initData
        : String.fromCharCode.apply(
            null,
            // @ts-ignore: TS2345 because typed array Uint16Array is required there
            new Uint16Array(initData.buffer || initData)
          )
    return skd.split(';', 2)[1]
  },

  // eslint-disable-next-line max-params
  getLicense(_emeOptions, contentId, keyMessage, callback) {
    const licenseUrl = options.licenseUri.replace('{asset_id}', contentId)
    fetch(licenseUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/octet-stream',
      },
      body: keyMessage,
    })
      .then((response) => {
        if (response.ok) {
          return response
        } else {
          throw response
        }
      })
      .then((response) => response.arrayBuffer())
      .then((responseBody) => callback(null, responseBody))
      .catch((error) => callback(error))
  },
})
