import {
  appendCmcdHeaders,
  appendCmcdQuery,
  uuid,
} from '@svta/common-media-library'
import videojs, { type Src, type TPlayer } from 'video.js'
import { CmcdDataCollector } from './CmcdDataCollector'

//
// Video.js plugins guide: https://videojs.com/guides/plugins/
//

// base plugin constructor
const BasePlugin: Src.Plugin = videojs.getPlugin('plugin') as Src.Plugin

/**
 * CMCD plugin options
 */
export interface CmcdPluginOptions {
  cid?: string
  sid?: string
  useHeaders?: boolean
}

/**
 * Default options for the CMCD plugin
 */
const defaults: CmcdPluginOptions = {
  useHeaders: false,
}

/**
 * CMCD plugin,
 * based on https://github.com/montevideo-tech/videojs-cmcd/blob/main/src/plugin.js
 */
export class CmcdPlugin extends BasePlugin {
  static VERSION = '2.0.0' // distinguish from the original plugin `@montevideo-tech/videojs-cmcd`

  declare player: TPlayer
  collector: CmcdDataCollector

  // config
  options: CmcdPluginOptions
  useHeaders?: boolean

  // data, needed for collector
  waiting: boolean = true
  cid?: string
  sid?: string

  constructor(player: TPlayer, options?: CmcdPluginOptions) {
    super(player)

    this.options = videojs.obj.merge(defaults, options)
    this.cid = this.options.cid
    this.sid = this.options.sid || uuid()

    // instantiate collector
    this.collector = new CmcdDataCollector(this.player)

    player.addClass('vjs-cmcd')

    player.on('xhr-hooks-ready', this.enable)
    player.on('waiting', this.setWaiting)
    player.on(['loadedmetadata', 'canplay'], this.setNoWaiting)
  }

  setId({ sid, cid }: Pick<CmcdPluginOptions, 'sid' | 'cid'> = {}) {
    if (sid) this.sid = sid
    if (cid) this.cid = cid
  }

  enable = () => {
    const xhr = this.player.tech_?.vhs?.xhr
    xhr?.onRequest(this.xhrRequestHook)
  }

  disable = () => {
    const xhr = this.player.tech_?.vhs?.xhr
    xhr?.offRequest(this.xhrRequestHook)
  }

  setWaiting = () => {
    this.waiting = true
  }

  setNoWaiting = () => {
    this.waiting = false
  }

  xhrRequestHook = (context) => {
    const keys = this.collector.collect({
      uri: context.uri,
      src: this.player.currentSrc(),
      waiting: this.waiting,
      cid: this.cid,
      sid: this.sid,
    })

    if (this.options.useHeaders) {
      const headers = appendCmcdHeaders({}, keys)
      context.beforeSend = (xhr: XMLHttpRequest) => {
        for (const h in headers) {
          xhr.setRequestHeader(h, headers[h])
        }
      }
    } else {
      context.uri = appendCmcdQuery(context.uri, keys)
    }

    return context
  }

  override dispose() {
    this.disable()
    this.player.off('xhr-hooks-ready', this.enable)
    this.player.off('waiting', this.setWaiting)
    this.player.off(['loadedmetadata', 'canplay'], this.setNoWaiting)
    super.dispose()
  }
}

// register cmcd plugin
videojs.registerPlugin('cmcd', CmcdPlugin)
