import videojs, { type TPlayer } from 'video.js'

// add default translations for some languages
import './en'
import './es'
import './fr'
import './hi'
import './th'
import './vi'

/**
 * Add a new language translations to videojs,
 * they will be merged with existing translations
 */
export const addLanguage: typeof videojs.addLanguage = (
  code: string,
  translations: Record<string, string>
) => {
  videojs.addLanguage(code, translations)

  // get all player instances and trigger language change for each one
  // technically we could add a language to videojs without any player instances
  const players: Record<string, TPlayer | null> = videojs.getPlayers()
  for (const player of Object.values(players)) {
    // skip disposed players
    if (!player || player.isDisposed()) continue

    // get current language
    const lang = player.language()

    // if current language is the same as the added translations,
    // trigger 'languagechange' event to re-localize components
    if (lang === code) {
      player.trigger('languagechange')
    }
  }
}
