import videojs, { type ExtOComponent, type TOptions } from 'video.js'
import { BasePlayer } from '|>/components'

/**
 * Helper function to get video.js component by id
 * Explicitly returns `BasePlayer` for 'Player' id, because it can be replaced with a custom one
 */
const getComponent = (id: string) =>
  id === 'Player' ? BasePlayer : videojs.getComponent(id)

/**
 * Helper function to get component class id from component registry
 */
const classId = (child: ExtOComponent | string) =>
  typeof child === 'string' ? child : child.componentClass

/**
 * Function to merge children for the player component in configuration
 * This function is needed because internal video.js merge logic doesn't merge arrays (like children),
 * but instead overwrites them, which is not what we want
 * @see https://github.com/videojs/video.js/blob/62f38446a5f01e85739bdb7207c7e992b57c1c86/src/js/utils/obj.js#L126
 */
export function configure(child?: ExtOComponent) {
  let children: any[] | undefined

  if (child) {
    // get default children from player component class
    const defaultChildren: any[] = []
    const Cls = getComponent(child.componentClass)
    if (Cls && Cls.prototype.options_ && Cls.prototype.options_.children) {
      Array.prototype.push.apply(
        defaultChildren,
        Cls.prototype.options_.children
      )
    }
    children = defaultChildren.slice()

    // append children from instance options
    // this also allows to override mandatory default children
    const instanceChildren: any[] = (child as TOptions).children ?? []
    for (const child of instanceChildren) {
      const id = classId(child)
      const idx = defaultChildren.findIndex((c) => id === classId(c))
      if (idx === -1) {
        children.push(child)
      } else {
        children[idx] = child
      }
    }
  }

  return (options: TOptions) => {
    // get mandatory children from options, they will go first
    const mandatory: any[] = options.children ?? []

    // instance options, they are not merged by default,
    // because Player instantiated differently than other children components,
    // so we need to handle it manually
    const instanceOptions = { ...child }
    delete instanceOptions.componentClass

    // return new options with instance options and children merged
    return {
      ...options,
      ...instanceOptions,
      children: mandatory.concat(children ?? []),
    }
  }
}

/**
 * Helper function to replace player component with a custom one
 * This relays on a fact, that video.js, when creating new player, gets `Player` component from registry
 * and uses it to create a new player instance, so if we replace `Player` component in the registry
 * with a custom one - it will be used to create a new player instance, effectively replacing the default one
 * @see https://github.com/videojs/video.js/blob/d2b9d5c974036e637df133f38a95649ab2230490/src/js/video.js#L179-L181
 */
export function replace(child: ExtOComponent) {
  const CustomPlayer: any = getComponent(child.componentClass)

  // custom player component must be registered
  if (!CustomPlayer) {
    throw new Error(`Custom player component ${child.componentClass} not found`)
  }

  // custom player component must be a subclass of BasePlayer
  if (
    CustomPlayer !== BasePlayer && // explicitly allow BasePlayer to be used
    !Object.prototype.isPrototypeOf.call(BasePlayer, CustomPlayer)
  ) {
    throw new Error(
      `Custom player component ${child.componentClass} is not a subclass of BasePlayer`
    )
  }

  // compare current player with custom one
  // do not use `getComponent` here because it will return BasePlayer
  const CurrentPlayer = videojs.getComponent('Player')
  if (CurrentPlayer === CustomPlayer) {
    return // do nothing if already replaced
  }

  // as long as custom player is a subclass of BasePlayer - we can safely replace it
  // this little hack with `players` property is to avoid this check in video.js:
  // https://github.com/videojs/video.js/blob/d2b9d5c974036e637df133f38a95649ab2230490/src/js/component.js#L2020-L2033

  // preserve original players object
  const players = BasePlayer.players
  BasePlayer.players = {} // <-- this is the hack

  // register new custom player component under the same name
  videojs.registerComponent('Player', CustomPlayer as any)

  // restore original players object
  BasePlayer.players = players
}
