import videojs, { type TOptions, type TPlayer } from 'video.js'
import 'videojs-contrib-eme'
import { Events } from '|>/shared/events'
import { MediaError } from '|>/shared/media-error'
import { supportedDrmsResolved } from '../drm'

//
// plugin documentation -> https://github.com/videojs/videojs-contrib-eme
//

const supportedDrms: string[] = []
supportedDrmsResolved.then((drms) => {
  for (const drm of drms.values()) {
    for (const keySystem of drm) {
      supportedDrms.push(keySystem)
    }
  }
})

// use middleware to check if source is supported
videojs.use('*', function (player) {
  return {
    setSource: function (source: Events.Media.Load, next) {
      // This solves the issue when switching channels with drm second channel just stuck in "seeking" state
      if ((player as unknown as TPlayer).eme)
        (player as unknown as TPlayer).eme.activeSrc = null

      if (source && source.src && source.keySystems) {
        let isSupported = false
        if (supportedDrms.length > 0) {
          for (const keySystem of Object.keys(source.keySystems)) {
            if (supportedDrms.includes(keySystem)) {
              isSupported = true
              break
            }
          }
        }
        if (!isSupported) {
          // do NOT call next(), and call (and return) error immediately
          return player.error(MediaError.MEDIA_ERR_ENCRYPTED)
        }
      }

      // pass null as the first argument to indicate that the source is not rejected
      next(null, source)
    },
  }
})

/**
 * Configure EME (Encrypted Media Extensions) plugin before player initialization
 */
export function configure(options: TOptions): TOptions {
  return options
}

/**
 * Injects EME (Encrypted Media Extensions) plugin and functionality into the player
 * It is required for playing DRM-protected content
 */
export function inject(player: TPlayer): TPlayer {
  // enable EME plugin
  player.eme()

  // re-enable EME plugin after player reset
  player.on('ready', () => {
    // `setupEmeListeners()` is used for manual setup for eme listeners after player.reset call
    // basically for any cases when player.tech.el is changed
    player.eme.setupEmeListeners()

    // `initLegacyFairplay()` is used to init the 'webkitneedskey' listener when using WebKitMediaKeys in Safari.
    // See https://github.com/videojs/videojs-contrib-eme?tab=readme-ov-file#initlegacyfairplay
    // This is useful because Safari currently supports both the modern `com.apple.fps` keysystem through MediaKeys
    // and the legacy `com.apple.fps.1_0` keysystem through WebKitMediaKeys.
    // Since this plugin will prefer using modern MediaKeys over WebkitMediaKeys initializing legacy fairplay
    // can be necessary for media using the legacy 1_0 keysystem.
    if (videojs.browser.IS_ANY_SAFARI || videojs.browser.IS_IOS) {
      player.eme.initLegacyFairplay()
    }
  })

  return player
}
