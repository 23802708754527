import videojs from 'video.js'
// import { type KeySystems, type LAProps } from '../videojs/index.h'
import { logger } from '|>/shared/logger'
import { supportedDrms, supportedDrmsResolved } from './detect'
import { type LA } from './index.h'
import { fairplay, playready, widevine } from './providers'

export * from './index.h'

// reexport detected supported DRM key systems
export { supportedDrms, supportedDrmsResolved }

type KeySystems = any

// https://github.com/videojs/videojs-contrib-eme
export function keySystems(la: LA): KeySystems | undefined {
  if (la == null) return
  const ks: KeySystems = {}

  if (la.WIDEVINE != null && supportedDrms.has('widevine')) {
    let robustnessLevel = 'SW_SECURE_CRYPTO'
    if (
      videojs.browser.IS_ANDROID &&
      Number(videojs.browser.ANDROID_VERSION) < 10
    ) {
      // HW_SECURE_CRYPTO for Android 9 only
      robustnessLevel = 'HW_SECURE_CRYPTO'
    }

    ks['com.widevine.alpha'] = widevine(la.WIDEVINE.provider, {
      url: la.WIDEVINE.licenseUrl,
      licenseHeaders: {
        'Content-type': null, // remove the internal default Content-Type
        'Content-Type': null, // remove the internal default Content-Type
        'content-type': null, // remove the internal default Content-Type
      },
      audioRobustness: robustnessLevel,
      videoRobustness: robustnessLevel,
    }) as any
  }

  if (la.PLAYREADY != null && supportedDrms.has('playready')) {
    ks['com.microsoft.playready'] = playready(la.PLAYREADY.provider, {
      url: la.PLAYREADY.licenseUrl,
    })
  }

  if (la.FAIRPLAY != null && supportedDrms.has('fairplay')) {
    if (la.FAIRPLAY.fpsCertificateUrl == null) {
      logger.error('❌ there is no fps certificate url defined!!!')
    } else {
      ks['com.apple.fps'] = fairplay(la.FAIRPLAY.provider, {
        licenseUri: la.FAIRPLAY.licenseUrl,
        certificateUri: la.FAIRPLAY.fpsCertificateUrl,
      })
      // for legacy fairplay support
      ks['com.apple.fps.1_0'] = ks['com.apple.fps']
    }
  }

  return ks
}

export function canPlayDrm(la?: LA): boolean {
  if (la == null) return false
  return (
    (supportedDrms.has('widevine') && la.WIDEVINE != null) ||
    (supportedDrms.has('playready') && la.PLAYREADY != null) ||
    (supportedDrms.has('fairplay') && la.FAIRPLAY != null)
  )
}
