import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import type { ExtOComponent, TPlayer } from 'video.js'
import { type Events } from '|>/shared/events'
import type { Configuration } from './core'
import {
  usePlayerImperativeHandle,
  VideojsPlayer,
  type PlayerRef,
  type VideojsBoxRef,
} from './videojs'

export type PlayerProps = {
  using?: ExtOComponent
  className?: string // to be able to add custom classes for external styling
  sourceUrl?: string
  drm?: any
  title?: string
  subtitle?: string
  onPlayNext?: () => void
  onPlayPrevious?: () => void
  onCloseClick?: (_?: undefined, event?: any) => void
  onReady?: (instance: TPlayer) => void
  onProgressReachedEnd?: () => void
  liveProgressBar?: Events.Media.ProgressBarChanged
  onLiveRewindTo?: (timestamp: number | undefined, event?: any) => void
  onGoLive?: (event?: any) => void
  isRewound?: boolean
  isRewindPending?: boolean
  rewoundTargetStartSec?: number
  rewoundUrlStartSec?: number
  isAutoLiveTracker?: boolean
  onProgramClick?: (event?: any) => void
  onRedirectTvClick?: (event?: any) => void
  chromecast?: Configuration['chromecast']
  cmcd?: Configuration['cmcd']
  isUrlRequestPending?: boolean
  externalError?: Events.Media.Error
  children?: React.ReactNode
  locale?: string
  muted?: boolean
  poster?: Events.Controls.SetPoster
  logo?: string
  seekStep?: number
}

/**
 * Player component
 */
export const Player = memo(forwardRef(_Player))
function _Player(
  props: PlayerProps,
  ref: React.ForwardedRef<PlayerRef>
): JSX.Element {
  console.log('💥', props)

  // whole list of @setplex/player props - PlayerViewProps
  // -> libraries/player/src/PlayerView.h.ts
  {
    /*
    id?: number
    !sourceUrl: string
    drm?: { la: LAProps }
    ?autoplay?: AutoPlayOptions
    muted?: boolean
    isRestrictedMode?: boolean
    !title?: string
    subtitle?: string
    externalError?: VideojsMediaError
    !onReturnToPrevPageClick?: () => void
    onPlaybackEnded?: () => void
    onPlayNext?: () => void
    onPlayPrevious?: () => void
    onAnalyticsStartPlaying?: AnalyticsStartPlaying
    onAnalyticsStopPausePlaying?: AnalyticsStopPausePlaying
    onAnalyticsContentWatching?: AnalyticsContentWatching
    translates?: Record<string, string>
    onInfoButtonClick?: () => void
    event?: PlayingEvent
    isWindowMode?: boolean
    isUrlRequestPending?: boolean
    isLiveRewindAllowed?: boolean
    rewindTo?: (isRewind?: boolean) => void
    rewindStartStamp?: number
    streamTimeStart?: number
    streamTimeEnd?: number
    disabled?: boolean
    isLiveUi?: boolean
    children?: React.ReactNode // this prop must be used only for pin lock pop-up
    setLatestWatchedPosition?: ISetLatestPositionFunction
    ?removeLatestWatchedPosition?: IRemoveLatestPositionFunction
    stoppedTime?: number
    onDataLoaded?: () => void
    npawData?: IPlayerNpaw
    */
  }

  // props for player in epg page
  // -> apps/tria/src/routes/epg/root/ui/components/PlayerWindow.tsx
  {
    /*
        isRestrictedMode={true}
        id={selectedEpgChannel?.id}
        title={selectedEpgChannel?.name}
        subtitle={subtitle}
        translates={translates}
        onPlayNext={isChannels ? onPlayNext : undefined}
        onPlayPrevious={isChannels ? onPlayPrevious : undefined}
        onAnalyticsStartPlaying={sendAnalyticsDataStartEventCallback}
        onAnalyticsStopPausePlaying={sendAnalyticsDataStopEventCallback}
        onAnalyticsContentWatching={sendAnalyticsContentWatchingCallback}
        onDataLoaded={onDataLoaded}
        sourceUrl={playbackUrl}
        drm={drm}
        isWindowMode={true}
        externalError={externalError}
        onReturnToPrevPageClick={onReturnToPrevPageClick}
        muted={true}
        isLiveUi
        isLiveRewindAllowed={Boolean(selectedEpgChannel?.liveRewind)}
        streamTimeStart={activeProgram && activeProgram.startTime}
        rewindStartStamp={currentUrl?.timeShift}
        streamTimeEnd={currentUrl?.endTime}
        rewindTo={rewindTo}
        disabled={playerDisabled}
        npawData={playerNpawData}
    */
  }

  // props for player in event page
  // -> apps/tria/src/routes/player/event/ui.tsx
  {
    /*
        title={title}
        subtitle={subtitle}
        translates={translates}
        onReturnToPrevPageClick={router.navigateBack}
        onAnalyticsStartPlaying={sendAnalyticsDataStartEventCallback}
        onAnalyticsStopPausePlaying={sendAnalyticsDataStopEventCallback}
        onAnalyticsContentWatching={sendAnalyticsContentWatchingCallback}
        sourceUrl={playbackUrl}
        drm={drm}
        // test stream 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8'
        event={currentLiveEvent || undefined}
        onInfoButtonClick={openPopup}
        onPlaybackEnded={handlePlaybackEnded}
        isUrlRequestPending={isUrlRequestPending}
        isLiveRewindAllowed={
          currentLiveEvent?.liveRewind &&
          currentLiveEvent?.status === EventStatus.LIVE
        }
        isLiveUi={isLiveEvent}
        rewindTo={rewindTo}
        rewindStartStamp={timeShift}
        streamTimeStart={startTime}
        streamTimeEnd={currentLiveEvent?.liveEventEndTimeSec}
        externalError={title ? currentPlayerPageUrlError : null}
        npawData={playerNpawData}
        disabled={playerDisabled}
    */
  }

  // props for player in tv page
  // -> apps/tria/src/routes/player/tv/ui.tsx
  {
    /*
        title={title}
        translates={translates}
        subtitle={subtitle}
        onPlayNext={isChannels ? onPlayNext : undefined}
        onPlayPrevious={isChannels ? onPlayPrevious : undefined}
        onReturnToPrevPageClick={router.navigateBack}
        onAnalyticsStartPlaying={sendAnalyticsDataStartEventCallback}
        onAnalyticsStopPausePlaying={sendAnalyticsDataStopEventCallback}
        onAnalyticsContentWatching={sendAnalyticsContentWatchingCallback}
        onDataLoaded={onDataLoaded}
        sourceUrl={playbackUrl}
        externalError={title ? externalError : null}
        isUrlRequestPending={isUrlRequestPending || isEpgRequestPending}
        drm={drm}
        isLiveUi
        /* All rewind values are in sec * /
        rewindTo={rewindTo}
        isLiveRewindAllowed={liveRewind}
        rewindStartStamp={timeShift}
        streamTimeEnd={endTime}
        streamTimeStart={startTime}
        disabled={playerDisabled}
        npawData={playerNpawData}
    */
  }

  // props for player in movies page
  // -> apps/tria/src/widgets/Player/PlayerWithCover.tsx
  {
    /*
        title={title}
        translates={translates}
        subtitle={subtitle}
        onPlaybackEnded={onPlaybackEnded}
        onReturnToPrevPageClick={router.navigateBack}
        onAnalyticsStartPlaying={sendAnalyticsDataStartEventCallback}
        onAnalyticsStopPausePlaying={sendAnalyticsDataStopEventCallback}
        onAnalyticsContentWatching={sendAnalyticsContentWatchingCallback}
        sourceUrl={sourceUrl}
        drm={drm}
        externalError={title ? externalError : null}
        // test stream 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8'
        ?playlist={playlist}
        setLatestWatchedPosition={setLatestWatchedPosition}
        stoppedTime={stoppedTime}
        npawData={npawData}
        disabled={disabled}
        isLiveUi={isLiveUi}
    */
  }

  // container ref for player
  const containerRef = useRef<HTMLDivElement | null>(null)

  // box contains refs to video tag, player instance and slot, exposed by VideoJS component
  const [box, setBox] = useState<VideojsBoxRef | null>(null)
  const slot = box?.slot()

  const [isRenderedChildren, setHasRenderedChildren] = useState(false)

  // export imperative handle to the parent component
  usePlayerImperativeHandle(ref, box, containerRef)

  const onReady = useCallback(
    (box) => {
      props.onReady?.(box.player())
      setBox(box)
    },
    [props.onReady]
  )

  useEffect(() => {
    setHasRenderedChildren((slot?.children.length ?? 0) > 0)
  }, [props.children, slot?.children.length])

  return (
    <div ref={containerRef} className={`vjs-pureya ${props.className ?? ''}`}>
      <VideojsPlayer
        using={props.using}
        sourceUrl={props.sourceUrl}
        drm={props.drm}
        title={props.title}
        subtitle={props.subtitle}
        onPlayNext={props.onPlayNext}
        onPlayPrevious={props.onPlayPrevious}
        onClose={props.onCloseClick}
        onProgressReachedEnd={props.onProgressReachedEnd}
        liveProgressBar={props.liveProgressBar}
        onLiveRewindTo={props.onLiveRewindTo}
        onGoLive={props.onGoLive}
        isRewound={props.isRewound}
        isRewindPending={props.isRewindPending}
        rewoundTargetStartSec={props.rewoundTargetStartSec}
        rewoundUrlStartSec={props.rewoundUrlStartSec}
        isAutoLiveTracker={props.isAutoLiveTracker}
        onProgramClick={props.onProgramClick}
        onRedirectTvClick={props.onRedirectTvClick}
        externalError={props.externalError}
        chromecast={props.chromecast}
        cmcd={props.cmcd}
        onReady={onReady}
        isUrlRequestPending={props.isUrlRequestPending}
        locale={props.locale}
        muted={props.muted}
        isRenderedChildren={isRenderedChildren}
        poster={props.poster}
        logo={props.logo}
        seekStep={props.seekStep}
      />
      {/* render children into provided slot */}
      {slot && props.children && createPortal(props.children, slot)}
    </div>
  )
}
