import { findPosition, getBoundingClientRect } from '|>/shared/h'
import { absoluteTime } from '|>/shared/utils'
import { register } from '|>/shared/vjs'
import { BaseTimeTooltip } from '../base'

/**
 * Display a time above the progress bar
 */
@register
export class TimeTooltip extends BaseTimeTooltip {
  override updateTime(
    rect: { left: number; top: number; width: number; height: number },
    point: number,
    time: number,
    cb?: Function
  ) {
    this.requestNamedAnimationFrame('TimeTooltip#updateTime', () => {
      const content = absoluteTime({ time })
      this.update(rect, point, content)
      if (cb) cb()
    })
  }

  //
  override update(
    seekBarRect: {
      left: number
      top: number
      width: number
      height: number
      right?: number
    },
    seekBarPointPx: number,
    content: string
  ) {
    const tooltipRect = findPosition(this.el_)
    const playerRect = getBoundingClientRect(this.player_.el())

    // do nothing if either rect isn't available
    // for example, if the player isn't in the DOM for testing
    if (!playerRect || !tooltipRect) {
      return
    }

    // This is the space left of the `seekBarPoint` available within the bounds
    // of the player. We calculate any gap between the left edge of the player
    // and the left edge of the `SeekBar` and add the number of pixels in the
    // `SeekBar` before hitting the `seekBarPoint`
    let spaceLeftOfPoint = seekBarRect.left - playerRect.left + seekBarPointPx

    // This is the space right of the `seekBarPoint` available within the bounds
    // of the player. We calculate the number of pixels from the `seekBarPoint`
    // to the right edge of the `SeekBar` and add to that any gap between the
    // right edge of the `SeekBar` and the player.
    let spaceRightOfPoint = seekBarRect.right
      ? seekBarRect.width -
        seekBarPointPx +
        (playerRect.right - seekBarRect.right)
      : null

    // spaceRightOfPoint is always NaN for mouse time display
    // because the seekbarRect does not have a right property. This causes
    // the mouse tool tip to be truncated when it's close to the right edge of the player.
    // In such cases, we ignore the `playerRect.right - seekBarRect.right` value when calculating.
    // For the sake of consistency, we ignore seekBarRect.left - playerRect.left for the left edge.
    if (!spaceRightOfPoint) {
      spaceRightOfPoint = seekBarRect.width - seekBarPointPx
      spaceLeftOfPoint = seekBarPointPx + seekBarRect.left
    }

    // This is the number of pixels by which the tooltip will need to be pulled
    // further to the right to center it over the `seekBarPoint`.
    let pullTooltipBy = tooltipRect.width / 2

    // Adjust the `pullTooltipBy` distance to the left or right depending on
    // the results of the space calculations above.
    if (spaceLeftOfPoint < pullTooltipBy) {
      pullTooltipBy += pullTooltipBy - spaceLeftOfPoint
    } else if (spaceRightOfPoint < pullTooltipBy) {
      pullTooltipBy = spaceRightOfPoint
    }

    // Due to the imprecision of decimal/ratio based calculations and varying
    // rounding behaviors, there are cases where the spacing adjustment is off
    // by a pixel or two. This adds insurance to these calculations.
    if (pullTooltipBy < 0) {
      pullTooltipBy = 0
    } else if (pullTooltipBy > tooltipRect.width) {
      pullTooltipBy = tooltipRect.width
    }

    // prevent small width fluctuations within 0.4px from
    // changing the value below.
    // This really helps for live to prevent the play
    // progress time tooltip from jittering
    pullTooltipBy = Math.round(pullTooltipBy)

    this.el_.style.right = `-${pullTooltipBy}px`
    this.write(content)
  }
}
