import { register } from '|>/shared/vjs'
import { BaseButton } from '../base'
import { Events } from '|>/shared/events'

import './program-button.css'

/**
 * Redirect to tv channel button component
 */
@register
export class ProgramButton extends BaseButton {
  override handleClick(event: Event) {
    event.preventDefault()
    event.stopPropagation()

    this.player_.trigger(Events.Player.ProgramClick)
  }
}

ProgramButton.options = {
  className: 'vjs-x-program-button',
}
