import { Events } from '|>/shared/events'
import { on, register } from '|>/shared/vjs'
import { BaseSeekToLive } from '../base'

import './seek-to-live.css'

@register
export class SeekToLive extends BaseSeekToLive {
  @on(Events.Media.IsRewound)
  private isRewound: Events.Media.IsRewound = false

  @on(Events.Media.IsAutoLiveTracker)
  private isAutoLiveTracker: Events.Media.IsAutoLiveTracker = false

  @on(Events.Media.IsRewound)
  override updateLiveEdgeStatus() {
    if (this.isAutoLiveTracker) return super.updateLiveEdgeStatus()

    if (Boolean(this.isRewound)) {
      this.setAttribute('aria-disabled', 'false')
      this.removeClass('vjs-at-live-edge')
    } else {
      this.setAttribute('aria-disabled', 'true')
      this.addClass('vjs-at-live-edge')
    }
  }

  override handleClick() {
    // TODO debounce
    if (this.isAutoLiveTracker) return super.updateLiveEdgeStatus()

    this.player_.trigger(Events.Player.GoLive)
  }
}

SeekToLive.options = {
  className: 'vjs-x-seek-to-live-control',
}
