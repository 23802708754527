import videojs from 'video.js'
import vi from 'video.js/dist/lang/vi.json'

videojs.addLanguage('vi', vi)
videojs.addLanguage('vi', {
  'Error': 'Lỗi',
  'Error Code': 'Mã lỗi',

  // custom vietnamese translations
  'subtitles off': 'Tắt',
  'captions off': 'Tắt',
  'audio off': 'Tắt',
  'Default': 'Mặc định',
  'Unknown': 'Mặc định',
})
