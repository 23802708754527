import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'

/**
 * Mouse bar
 */
@register
export class MouseBar extends BaseComponent {
  constructor(player, options) {
    super(player, options)
  }

  update(
    rect: { left: number; top: number; width: number; height: number },
    point: number
  ) {
    this.el_.style.width = `${rect.width * point}px`
  }
}

MouseBar.options = {
  barName: 'mouseBar',
  className: 'vjs-x-mouse-bar',
}
