export const throttleNext = (func, throttleForMs) => {
  let isThrottled = false

  return function (...args) {
    if (!isThrottled) {
      // @ts-ignore
      func.apply(this, args)
      isThrottled = true

      window.setTimeout(() => (isThrottled = false), throttleForMs)
    }
  }
}
