import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'
import { LiveCurrentTimeDisplay } from './live-current-time-display'
import { LiveProgressControl } from './live-progress-control'
import { VodCurrentTimeDisplay } from './vod-current-time-display'
import { VODProgressControl } from './vod-progress-control'

import './progress-control.css'

/**
 * Progress bar control
 */
@register
export class ProgressControl extends BaseComponent {}

ProgressControl.options = {
  className: 'vjs-x-progress-control',
  children: [
    LiveProgressControl,
    VODProgressControl,
    LiveCurrentTimeDisplay,
    VodCurrentTimeDisplay,
  ],
}
