import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'

/**
 * Live full (could be partly disabled) progress bar
 */
@register
export class LiveFullBar extends BaseComponent {}

LiveFullBar.options = {
  className: 'vjs-x-live-full-progress-bar',
}
