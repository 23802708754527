export function isPromise(value) {
  return (
    value !== undefined && value !== null && typeof value.then === 'function'
  )
}

export function silencePromise(value) {
  if (isPromise(value)) {
    value.then(null, () => {})
  }
}
